<template>
  <div class="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
    <div class="chat-window flex h-full max-w-full flex-1 flex-col">
      <main class="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
        <div class="chat-messages overflow-y-auto pb-[180px]" ref="messagesList">
          <div v-if="currentChat">
            <div class="chat-messages-list">
              <MessageItem
                  v-for="(message, index) in currentChat.messages"
                  :key="index"
                  :avatar="settingStore.avatar"
                  :idx="index"
                  :message="message"
                  @re-generate-response="reGenerate"
                  @edit-user-message="editUserMessage"
              ></MessageItem>
            </div>
          </div>
        </div>

        <div class="chat-input absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent bg-white dark:bg-gray-800 md:!bg-transparent bg-gradient-to-b from-transparent via-white to-white dark:from-transparent dark:via-gray-800 dark:to-gray-900 pt-2">
          <div class="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
            <div class="relative flex h-full flex-1 md:flex-col flex-row-reverse">
              <div class="flex items-end md:justify-center ml-1 md:w-full md:m-auto md:mb-1 mr-2 gap-0 md:gap-2 mb-1">
                <button v-if="loadingRequest" @click="stopGeneration"
                        class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm p-2 md:mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    <span class="flex w-full items-center justify-center gap-2">
                      <StopCircleIcon class="w-4 h-4"/>
                      <span class="md:block hidden">Stop generate</span>
                    </span>
                </button>
                <button
                    v-if="!loadingRequest && currentChat?.messages?.length" @click="reGenerate()"
                    class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm p-2 md:mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    <span class="flex w-full items-center justify-center gap-2">
                      <ArrowPathIcon class="w-4 h-4"/>
                      <span class="md:block hidden">Regenerate response</span>
                    </span>
                </button>
              </div>
              <div class="flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-md ring-1 ring-inset ring-gray-300 dark:ring-gray-500 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-300 dark:focus-within:ring-blue-600">
                <div v-show="showInputSuggestions" class="flex flex-col-reverse md:flex-row gap-2 items-end absolute z-10 left-0 bottom-0 mb-14">
                  <div class="overflow-y-auto border border-gray-300 dark:border-gray-900 rounded-md shadow-md w-60 bg-white dark:bg-gray-700 max-h-32 md:max-h-96">
                    <button @mousedown.prevent @click.stop="$emit('create-prompt')" class="flex w-full border-b border-gray-300 dark:border-gray-900 items-center px-4 py-3 text-sm hover:text-orange-400 font-bold text-gray-700 dark:text-gray-200">
                      <PlusIcon class="w-4 h-4 mr-1"/>
                      New Prompt
                    </button>
                    <ul ref="suggestionsContainerRef" class="py-2 text-sm text-gray-700 dark:text-gray-200" @mousedown.prevent>
                    </ul>
                  </div>
                  <div v-show="selectedSuggestionIdx !== null" class="overflow-y-auto border border-gray-300 dark:border-gray-900 rounded-md shadow-md w-60 bg-white dark:bg-gray-700 max-h-32 md:max-h-96">
                    <div class="p-2 text-sm text-gray-700 dark:text-gray-200" ref="suggestionDetailRef"></div>
                  </div>
                </div>
                <AutosizeTextarea :maxHeight="125" class="m-0 w-full resize-none border-0 bg-transparent p-0 pr-7 border-transparent focus:outline-none focus:border-transparent disabled:opacity-40 focus:ring-0 dark:bg-transparent pl-2 md:pl-0"
                                  v-model="userInput"
                                  @keydown="onUserEnter"
                                  @input="onUserInput"
                                  @blur="userInput.trim() ==='/'?userInput='':null;showInputSuggestions=false"
                                  placeholder="Send a message..."
                                  rows="1"></AutosizeTextarea>
                <button v-if="!loadingRequest" @click="sendMessage" :disabled="loadingRequest || !userInput.trim()"
                        class="absolute p-1 rounded-md text-gray-500 bottom-1.5 md:bottom-2.5 hover:bg-gray-100 enabled:dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent right-1 md:right-2 disabled:opacity-40">
                  <PaperAirplaneIcon class="h-4 w-4"/>
                </button>
                <span v-if="loadingRequest" class="absolute p-1 rounded-md text-gray-500 bottom-1.5 md:bottom-2.5 right-1 md:right-2">
                  <LoadingIcon class="h-5 w-10 mr-1"/>
                </span>
              </div>
            </div>
          </div>
          <div v-if="currentChat?.systemRole" class="font-semibold px-3 pt-2 pb-3 text-center text-xs text-black/50 dark:text-white/50 md:px-4 md:pt-3 md:pb-3">
            <div class="line-clamp-3">{{ currentChat?.systemRole }}</div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import {
  ArrowPathIcon,
  PaperAirplaneIcon,
  StopCircleIcon,
  PlusIcon,
} from '@heroicons/vue/24/solid';
import LoadingIcon from "@/assets/icons/LoadingIcon.vue";

</script>

<script>
import MessageItem from './MessageItem';

import {requestCompletion} from '@/scripts/OpenAIRequest';
import {renderMarkdown} from '@/scripts/MessageRender';
import {ScrollToBottomController} from "@/scripts/Utils";
import {useSettingsStore} from "@/stores/settings";
import {mapState, mapWritableState} from "pinia";
import {useChatsStore} from "@/stores/chat";
import AutosizeTextarea from "@/components/ui/AutosizeTextarea.vue"

export default {
  components: {
    MessageItem, AutosizeTextarea
  },
  props: {
    chatId: {
      type: Number,
      required: true
    },
  },
  data() {
    const settingStore = useSettingsStore();
    const chatsStore = useChatsStore();
    return {
      settingStore: settingStore,
      chatsStore: chatsStore,

      scrollToBottomController: null,

      showInputSuggestions: false,
      selectedSuggestionIdx: null,
      candidateSuggestions: [],
    };
  },
  async mounted() {
    this.scrollToBottomController = new ScrollToBottomController();
    this.scrollToBottom(true);
    // this.resizeInput();
    document.addEventListener("keydown", this.keyEvents);
  },

  computed: {
    userInput: {
      get() {
        return this.currentChat?.userInput || "";
      },
      set(value) {
        this.chatsStore.updateChatSettings({userInput: value});
      },
    },
    currentChat() {
      return this.chatsStore.getChat(this.chatId);
    },
    ...mapState(useChatsStore, ['requestingChat']),
    ...mapWritableState(useChatsStore, ['requestingChatId', 'loadingRequest', 'controller', 'prompts']),
  },

  watch: {
    currentChat() {
      this.scrollToBottom(true);
    },
    // userInput() {
    //   this.resizeInput();
    // },
  },


  methods: {
    messageToPrompt(messages) {
      return messages.map(item => ({
        role: item.role,
        content: item.content,
      }));
    },

    getContext(contextLength, endIdx /*excluded*/) {
      const currentChat = this.currentChat;
      if (endIdx === undefined) endIdx = currentChat.messages.length;
      const context = this.messageToPrompt(currentChat.messages.slice(Math.max(0, endIdx - contextLength), endIdx));
      if (currentChat.systemRole?.trim()) {
        context.unshift({role: "system", content: currentChat.systemRole?.trim()})
      }
      return context
    },

    onUserEnter(event) {
      if (event.shiftKey || event.key !== 'Enter') {
        return;
      }
      event.preventDefault();
      if (this.showInputSuggestions) {
        if (this.candidateSuggestions.length) {
          this.userInput = this.candidateSuggestions[this.selectedSuggestionIdx].prompt;
          this.showInputSuggestions = false;
        }
        return;
      }
      this.sendMessage();
    },

    async sendMessage() {
      if (!this.userInput.trim() || this.loadingRequest) return;

      const userMessage = {role: "user", content: this.userInput.trim()};

      const contextPrompt = this.getContext(this.currentChat.contextLength);
      contextPrompt.push(userMessage);

      const messages = this.currentChat.messages;
      messages.push(userMessage);

      this.userInput = "";

      await this.chatsStore.saveChat(this.currentChat);
      await this.sendRequest(contextPrompt);

    },
    summarizeChat() {
      const chat = this.requestingChat;
      if (chat.title.trim() === 'New Chat' && chat.messages.reduce((pre, cur) => pre + cur.content.length, 0) >= 50) {
        let prompt = this.messageToPrompt(chat.messages);
        prompt.push({
          role: 'user',
          content: 'Please generate a four to five word title summarizing our conversation without any lead-in, punctuation, quotation marks, periods, symbols, or additional text. Remove enclosing quotation marks.'
        });
        requestCompletion(
            prompt,
            {
              openaiKey: chat.openaiKey ? chat.openaiKey : this.settingStore.openaiKey,
              apiHost: chat.apiHost ? chat.apiHost : this.settingStore.apiHost,
              model: chat.model,
              temperature: chat.temperature,
              stream: false,
              onFinish: (newContent) => {
                this.chatsStore.updateChatSettings({title: newContent}, this.requestingChatId);
              },
            }
        );
      }
    },
    async sendRequest(prompt, userMsgIdx) {
      if (this.loadingRequest) return;

      this.loadingRequest = true;
      this.requestingChatId = this.chatId; // Store the chat ID when the request starts
      const requestingChat = this.requestingChat;
      requestingChat.status = 'requesting';

      // console.log("userMsgIdx: " + userMsgIdx, "messages length: " + this.currentChat.messages.length);

      let assistantMsgIdx;
      // new msg || last msg
      if (userMsgIdx === undefined || userMsgIdx === requestingChat.messages.length - 1) {
        // console.log("new msg || last msg");
        requestingChat.messages.push({role: "assistant", content: "", rendered_content: ""});
        assistantMsgIdx = requestingChat.messages.length - 1;
        this.scrollToBottom(true);
      } else if (requestingChat.messages[userMsgIdx + 1].role !== "assistant") {
        // insert after user msg
        // console.log("insert after user msg");
        requestingChat.messages.splice(userMsgIdx + 1, 0, {role: "assistant", content: "", rendered_content: ""});
        assistantMsgIdx = userMsgIdx + 1;
      } else {
        // modify existed assistant msg
        // console.log("modify existed assistant msg");
        requestingChat.messages[userMsgIdx + 1] = {role: "assistant", content: "", rendered_content: ""};
        assistantMsgIdx = userMsgIdx + 1;
      }

      this.controller = new AbortController();

      // await new Promise((resolve)=> setTimeout(resolve, 1000000))
      await requestCompletion(
          prompt,
          {
            openaiKey: requestingChat.openaiKey ? requestingChat.openaiKey : this.settingStore.openaiKey,
            apiHost: requestingChat.apiHost ? requestingChat.apiHost : this.settingStore.apiHost,
            model: requestingChat.model,
            temperature: requestingChat.temperature,
            stream: true,
            onUpdate: (newContent) => {
              const msg = requestingChat.messages[assistantMsgIdx];
              msg.content = newContent;
              msg.rendered_content = renderMarkdown(newContent);
              msg.status = 'updating';
              this.scrollToBottom();
            },
            onFinish: (newContent) => {
              const msg = requestingChat.messages[assistantMsgIdx];
              msg.status = 'finished';
              msg.content = newContent;
              msg.rendered_content = renderMarkdown(newContent);
              this.chatsStore.saveChat(requestingChat);
              this.loadingRequest = false;
              requestingChat.status = null;
              this.scrollToBottom();
            },
            onError: (err) => {
              const msg = requestingChat.messages[assistantMsgIdx];
              if (err.name === 'AbortError') {
                msg.status = 'canceled';
                console.log('Request was cancelled by the user');
              } else {
                msg.status = 'error';
                msg.rendered_content = `<span class="text-red-500">${err.message}</span>`;
                this.$toast.error(err.message);
              }
              this.loadingRequest = false;
              requestingChat.status = null;
            },
          },
          this.controller,
      );

      this.summarizeChat();
    },
    stopGeneration() {
      if (this.controller) {
        this.controller.abort();
        this.controller = null;
      }
      this.loadingRequest = false;
    },

    editUserMessage(idx, newContent) {
      if (this.loadingRequest) {
        this.$toast.error("Please wait for current request finishing!");
        return
      }
      const messages = this.currentChat.messages;
      messages[idx].content = newContent;
      this.reGenerate(idx);
    },
    async reGenerate(msgIdx) {
      if (this.loadingRequest) {
        this.$toast.error("Please wait for current request finishing!");
        return
      }
      if (msgIdx === undefined) {
        const lastUserMsgIdx = this.currentChat.messages.findLastIndex(msg => msg.role === "user");
        if (lastUserMsgIdx === -1) {
          this.$toast.error("No user message found to regenerate.");
          return;
        }
        msgIdx = lastUserMsgIdx
      } else {
        msgIdx = this.currentChat.messages.slice(0, msgIdx + 1).findLastIndex(msg => msg.role === "user");
      }

      const contextPrompt = this.getContext(this.currentChat.contextLength, msgIdx);

      contextPrompt.push({role: "user", content: this.currentChat.messages[msgIdx].content})
      await this.sendRequest(contextPrompt, msgIdx);
    },

    onUserInput() {
      if (this.userInput.startsWith('/')) {
        this.showInputSuggestions = true;
        const container = this.$refs.suggestionsContainerRef;

        const suggestionNodes = [];
        const candidateSuggestions = [];
        const query = this.userInput.trim().slice(1).toLowerCase();

        this.prompts.filter((item) => item.act?.toLowerCase().includes(query) || item.name?.toLowerCase().includes(query)).forEach((item, idx) => {
          let el = document.createElement("a");
          el.innerText = item.name;
          el.onclick = () => {
            this.selectSuggestion(idx, false);
          };
          el.classList.add(...'cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'.split(' '))

          suggestionNodes.push(el);
          candidateSuggestions.push(item);
        });
        this.candidateSuggestions = candidateSuggestions;

        if (!suggestionNodes.length) {
          this.selectedSuggestionIdx = null;
          let el = document.createElement("a");
          el.innerText = 'No matches.';
          el.classList.add(...'block px-4 py-2'.split(' '))
          container.replaceChildren(el);
        } else {
          this.selectedSuggestionIdx = 0;
          container.replaceChildren(...suggestionNodes);
          this.selectSuggestion(this.selectedSuggestionIdx);
          this.$refs.suggestionDetailRef.innerText = candidateSuggestions[this.selectedSuggestionIdx].prompt;
        }

      } else {
        this.showInputSuggestions = false;
      }
    },

    selectSuggestion(idx, scrollIntoView = true) {
      const children = this.$refs.suggestionsContainerRef.children;
      const promptDetail = this.$refs.suggestionDetailRef;

      const selectedClasses = ['bg-gray-100', 'text-orange-400', 'dark:bg-gray-600'];

      children[this.selectedSuggestionIdx].classList.remove(...selectedClasses);
      this.selectedSuggestionIdx = idx;
      children[this.selectedSuggestionIdx].classList.add(...selectedClasses);
      if (scrollIntoView) {
        children[this.selectedSuggestionIdx].scrollIntoView();
      }
      promptDetail.innerText = this.candidateSuggestions[this.selectedSuggestionIdx].prompt;
    },

    scrollToBottom(force) {
      this.$nextTick(() => {
        const target = this.$refs.messagesList;
        this.scrollToBottomController.toBottom(target, force);
      });
    },
    keyEvents(event) {
      if (this.showInputSuggestions) {
        if (event.key === 'ArrowDown' && this.selectedSuggestionIdx < this.candidateSuggestions.length - 1) {
          this.selectSuggestion(this.selectedSuggestionIdx + 1);
          event.preventDefault();
        } else if (event.key === 'ArrowUp' && this.selectedSuggestionIdx > 0) {
          this.selectSuggestion(this.selectedSuggestionIdx - 1);
          event.preventDefault();
        }
      }
      // if (event.ctrlKey && event.code === "KeyN") {
      //   event.preventDefault();
      //   this.createChat();
      // }
    },
  },
}
</script>
