export default {
    processChatGPTConversation(conversation) {
        const modelMapping = {
            "text-davinci-002-render-sha": "GPT-3.5-TURBO",
            "text-davinci-002-render-paid": "GPT-3.5-TURBO",
            "gpt-4": "GPT-4"
        };
        var __defProp = Object.defineProperty;
        var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
        var __publicField = (obj, key, value) => {
            __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
            return value;
        };

        class LinkedListItem {
            constructor(value) {
                __publicField(this, "value");
                __publicField(this, "child", null);
                this.value = value;
            }
        }

        function processConversation(conversation, conversationChoices = []) {
            var _a, _b, _c, _d;
            const title = conversation.title || "ChatGPT Conversation";
            const createTime = conversation.create_time;
            const modelSlug = ((_c = (_b = (_a = Object.values(conversation.mapping).find((node2) => {
                var _a2, _b2;
                return (_b2 = (_a2 = node2.message) == null ? void 0 : _a2.metadata) == null ? void 0 : _b2.model_slug;
            })) == null ? void 0 : _a.message) == null ? void 0 : _b.metadata) == null ? void 0 : _c.model_slug) || "";
            const model = modelSlug ? modelMapping[modelSlug] || "" : "";
            const result = [];
            const nodes = Object.values(conversation.mapping);
            const root2 = nodes.find((node2) => !node2.parent);
            if (!root2)
                throw new Error("No root node found.");
            const nodeMap = new Map(Object.entries(conversation.mapping));
            const tail = new LinkedListItem(root2);
            const queue = [tail];
            let index2 = -1;
            while (queue.length > 0) {
                const current = queue.shift();
                const node2 = nodeMap.get(current.value.id);
                if (!node2)
                    throw new Error("No node found.");
                const role = (_d = node2.message) == null ? void 0 : _d.author.role;
                if (role === "assistant" || role === "user") {
                    result.push(node2);
                }
                if (node2.children.length === 0)
                    continue;
                const _last = node2.children.length - 1;
                const choice = conversationChoices[index2++] ?? _last;
                const childId = node2.children[choice] ?? node2.children[_last];
                if (!childId)
                    throw new Error("No child node found.");
                const child = nodeMap.get(childId);
                if (!child)
                    throw new Error("No child node found.");
                const childItem = new LinkedListItem(child);
                current.child = childItem;
                queue.push(childItem);
            }
            return {
                id: conversation.id,
                title,
                modelSlug,
                model,
                createTime,
                conversationNodes: result
            };
        }

        return processConversation(conversation);
    }

}