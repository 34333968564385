<template>
    <div class="h-full w-full text-gray-900 dark:text-gray-100">
        <!--    <div class="w-full bg-white dark:bg-gray-800" v-if="currentGroups.length > 1">-->
        <!--      <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">-->
        <!--        <nav class="flex overflow-x-auto -mb-px">-->
        <!--          <div v-for="(filename, index) in currentGroups" :key="index" class="mr-2">-->
        <!--            <button @click="selectedFileIdx=index"-->
        <!--                    :class="[index===selectedFileIdx|| (index===0 && !selectedFileIdx) ? 'text-amber-600 border-amber-600' : '']"-->
        <!--                    class="flex whitespace-nowrap items-center px-2 py-1 border-b-2 border-transparent rounded-t-lg hover:text-amber-600 hover:border-amber-600">-->
        <!--              <LoadingIcon v-if="getFirstMsgIdx(currentTask, index, 'updating') !== -1" class="mr-0.5 w-8 h-4"/>-->
        <!--              <CheckCircleIcon v-else-if="isFileFinished(index)" class="mr-0.5 w-4 h-4"/>-->
        <!--              <StopCircleIcon v-else class="mr-0.5 w-4 h-4"/>-->
        <!--              {{ filename }}-->
        <!--            </button>-->
        <!--          </div>-->
        <!--        </nav>-->
        <!--      </div>-->
        <!--    </div>-->
        <main class="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
            <div class="text-gray-900 dark:text-gray-100 overflow-y-auto pb-48" ref="messagesContainerRef">
                <div v-for="(message, index) in currentMessages" :key="index" :id="'message-' + index" class="grid grid-cols-2 gap-2 items-start p-2">
                    <div class="group p-1.5 border rounded bg-gray-200 dark:bg-gray-800 dark:border-gray-600 whitespace-pre-line">
                        <div class="bg-white dark:bg-gray-700 p-2 rounded">{{ message.filename ? message.input_variables : message.content }}</div>
                        <div class="flex mt-1.5">
                            <button @click="deleteMessage(index)" title="Delete"
                                    class="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400">
                                <TrashIcon class="h-4 w-4"/>
                            </button>
                            <button @click="copyContent(message.filename ? message.input_variables : message.content)" title="Copy"
                                    class="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400">
                                <DocumentDuplicateIcon class="h-4 w-4"/>
                            </button>
                        </div>
                    </div>
                    <div class="group p-1.5 border rounded bg-gray-200 dark:bg-gray-800 dark:border-gray-600">
                        <div v-if="message.response" class="bg-white dark:bg-gray-700 p-2 rounded markdown-body" v-html="message.rendered_response"></div>
                        <LoadingIcon class="h-5 w-10 hidden" v-else-if="isLoadingTaskRequest && (message.status === 'started' || message.status === 'error') && !message.rendered_response"/>
                        <div v-else class="bg-white dark:bg-gray-700 p-2 text-gray-400 dark:text-gray-200 rounded markdown-body">Waiting for request...</div>
                        <div class="flex mt-1.5" v-if="message.response">
                            <button @click="rerunTask(index)" title="Rerun"
                                    class="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400">
                                <ArrowPathIcon class="h-4 w-4"/>
                            </button>
                            <button @click="copyContent(message.response)" title="Copy"
                                    class="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400">
                                <DocumentDuplicateIcon class="h-4 w-4"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <div class="absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent bg-white dark:bg-gray-800 md:!bg-transparent bg-gradient-to-b from-transparent via-white via-75% to-white dark:from-transparent dark:via-gray-800 dark:to-gray-900 pt-2">
            <div class="flex justify-center flex-1">
                <div class="p-1.5 border shadow rounded bg-gray-200 dark:bg-gray-800 dark:border-gray-600 w-full md:max-w-5xl lg:max-w-6xl">
                    <div class="w-full pb-0 flex flex-row justify-between text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                        <button @click="showCommandPanel=!showCommandPanel"
                                :class="{'': !showCommandPanel}"
                                class="self-center transition-colors hover:text-amber-600 bg-gray-50 dark:bg-gray-700 rounded hover:bg-opacity-50 p-0.5">
                            <MinusIcon v-if="showCommandPanel" class="w-4 h-4 stroke-[3px]"/>
                            <PlusIcon v-else class="w-4 h-4 stroke-[3px]"/>
                        </button>
                        <span class="center text-xs" ref="statusTextRef"></span>
                        <div class="ml-1" v-show="showCommandPanel">
                            <button @click="showInputPreview=false"
                                    :class="[!showInputPreview ? 'text-amber-600 bg-gray-50 dark:bg-gray-700' : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:text-gray-300']"
                                    class="mr-1 inline-block px-2 py-1 rounded-t">
                                Input
                            </button>
                            <button @click="showInputPreview=true"
                                    :class="[showInputPreview ? 'text-amber-600 bg-gray-50 dark:bg-gray-700' : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:text-gray-300']"
                                    class="inline-block px-2 py-1 rounded-t">
                                Preview
                            </button>
                        </div>
                    </div>
                    <div v-show="showCommandPanel" class="mb-1.5">
                        <div v-show="showInputPreview" class="bg-gray-50 dark:bg-gray-700 p-2 rounded-b text-sm whitespace-pre-line" v-html="formaInputHTML"></div>
                        <div v-show="!showInputPreview" ref="inputFields" class="flex flex-col gap-1.5">
                            <div v-for="(variableName, index) in taskVariables" :key="index">
                                <!--                <label class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">{{ variableName }}</label>-->
                                <AutosizeTextarea rows="3" v-model.trim="taskVariableValues[variableName]"
                                                  class="block p-2.5 w-full resize-y text-sm text-gray-900 bg-gray-50 rounded-b border-0 border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                  :placeholder="variableName"></AutosizeTextarea>
                            </div>
                        </div>
                    </div>
                    <div class="w-full flex items-center justify-center m-auto gap-2">
                        <button v-show="isLoadingTaskRequest" @click="stopTask()"
                                class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              <span class="flex w-full items-center justify-center gap-1">
                <StopCircleIcon class="w-4 h-4"/>
                <span class="md:block hidden">Stop</span>
              </span>
                        </button>
                        <button v-show="!isLoadingTaskRequest" @click="startTask(false)"
                                class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              <span class="flex w-full items-center justify-center gap-1">
                <PlayIcon class="w-4 h-4"/>
                <span class="md:block hidden">Start</span>
              </span>
                        </button>
                        <button v-show="!isLoadingTaskRequest" @click="startTask(true)"
                                class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              <span class="flex w-full items-center justify-center gap-1">
                <ArrowPathIcon class="w-4 h-4"/>
                <span class="md:block hidden">Rerun all</span>
              </span>
                        </button>
                        <button v-show="!isLoadingTaskRequest" @click="deleteAllMessages()"
                                class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              <span class="flex w-full items-center justify-center gap-1">
                <TrashIcon class="w-4 h-4"/>
                <span class="md:block hidden">Delete all messages</span>
              </span>
                        </button>
                        <button v-show="!isLoadingTaskRequest" @click="showUploadFileModal = true"
                                class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              <span class="flex w-full items-center justify-center gap-1">
                <CloudArrowUpIcon class="w-4 h-4"/>
                <span class="md:block hidden">Upload files</span>
              </span>
                        </button>
                    </div>
                    <!--            <button v-show="isLoadingTaskRequest" @click="stopTask()"-->
                    <!--                    class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded-lg text-sm px-2 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">-->
                    <!--              <span class="flex w-full items-center justify-center gap-1">-->
                    <!--                <StopCircleIcon class="w-4 h-4"/>-->
                    <!--                <span class="md:block hidden">Stop</span>-->
                    <!--              </span>-->
                    <!--            </button>-->
                    <!--            <button v-show="!isLoadingTaskRequest" @click="startTask(false)"-->
                    <!--                    class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded-lg text-sm px-2 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">-->
                    <!--              <span class="flex w-full items-center justify-center gap-1">-->
                    <!--                <PlayIcon class="w-4 h-4"/>-->
                    <!--                <span class="md:block hidden">Start</span>-->
                    <!--              </span>-->
                    <!--            </button>-->
                </div>
            </div>
        </div>
    </div>
    <UploadFileModal v-if="showUploadFileModal" :show="showUploadFileModal" :metaData="currentTask?.metaData"
                     @close="showUploadFileModal=false" @create-task-from-file="createTaskFromFile"/>
</template>

<script setup>
import {ArrowPathIcon, CloudArrowUpIcon, DocumentDuplicateIcon, PlayIcon, StopCircleIcon, TrashIcon} from '@heroicons/vue/24/solid';
import {MinusIcon, PlusIcon} from '@heroicons/vue/24/outline';
import LoadingIcon from "@/assets/icons/LoadingIcon.vue";
</script>

<script>
import {useSettingsStore} from "@/stores/settings";
import {useChatsStore} from "@/stores/chat";
import {mapState, mapWritableState} from "pinia";
import {copyToClipboard, ScrollToBottomController} from "@/scripts/Utils";
import UploadFileModal from "./modals/UploadFileModal";
import AutosizeTextarea from "./ui/AutosizeTextarea";

export default {
    components: {UploadFileModal, AutosizeTextarea},
    props: {
        taskId: {
            type: Number,
            required: true,
        },
    },
    data() {
        const settingStore = useSettingsStore();
        const chatsStore = useChatsStore();
        return {
            settingStore: settingStore,
            chatsStore: chatsStore,
            taskVariablesRegex: /{{(.*?)}}/g,
            taskVariableValues: {},
            // selectedFileIdx: null,

            showInputPreview: false,
            showCommandPanel: true,
            showUploadFileModal: false,
        }
    },
    computed: {
        currentMessages() {
            return this.currentTask?.messages ?? [];
        },

        userInput() {
            return this.currentTask?.userInput || "";
        },
        taskVariables() {
            const variableNames = [];
            let match;
            while ((match = this.taskVariablesRegex.exec(this.currentTask.taskPrompt)) !== null) {
                variableNames.push(match[1]);
            }
            return variableNames;
        },
        formaInputHTML() {
            const thisComponent = this;
            return this.currentTask.taskPrompt.replace(this.taskVariablesRegex, function (match, variable) {
                const value = thisComponent.taskVariableValues[variable];
                return value ? `<span class="text-green-500">${value}</span>` : `<span class="text-red-600">${match}</span>`
            });
        },

        currentTask() {
            return this.chatsStore.getChat(this.taskId);
        },
        ...mapState(useChatsStore, ['requestingTask']),
        ...mapWritableState(useChatsStore, ['requestingTaskId', 'isLoadingTaskRequest', 'taskRequestController']),
    },
    watch: {
        taskVariableValues: {
            handler(value) {
                this.chatsStore.updateChatSettings({userInput: JSON.stringify(value)});
            },
            deep: true,
        },
        showCommandPanel(val) {
            this.currentTask.showCommandPanel = val;
            this.chatsStore.saveChatSettings(this.currentTask);
        },

    },

    mounted() {
        this.taskVariableValues = JSON.parse(this.currentTask?.userInput ? this.currentTask.userInput : '{}');
        this.scrollToBottomController = new ScrollToBottomController();
        this.showCommandPanel = this.currentTask?.showCommandPanel ?? true;
        if (this.isLoadingTaskRequest && this.currentMessages.length) {
            const msgIdx = this.getFirstMsgIdx();
            this.scrollMessageIntoView(msgIdx === -1 ? this.currentMessages.length - 1 : msgIdx)
        } else {
            this.scrollToBottom(true);
        }
        this.chatsStore.addTaskEventListener(this);
    },

    unmounted() {
        this.chatsStore.removeTaskEventListener(this);
    },

    methods: {
        scrollMessageIntoView(msgIdx) {
            this.$nextTick(() => {
                const messageElement = this.$refs.messagesContainerRef?.querySelector('#message-' + msgIdx);
                if (messageElement) {
                    this.$refs.messagesContainerRef.scrollTo({
                        top: messageElement.offsetTop,
                        behavior: 'smooth',
                    })
                }
            });
        },

        getFirstMsgIdx(status = null) {
            const task = this.currentTask;
            const messages = task.messages;
            for (let i = 0; i < messages.length; i++) {
                if (!status && !messages[i].response) {
                    return i
                } else if (status && messages[i].status === status) {
                    return i
                }
            }
            return -1;
        },


        formatInput(variableValues) {
            return this.currentTask.taskPrompt.replace(this.taskVariablesRegex, function (match, variable) {
                return variableValues[variable] ?? variableValues;
            });
        },

        formatPrompt(input) {
            const context = [{
                role: "user",
                content: input
            }];
            if (this.currentTask.systemRole?.trim()) {
                context.unshift({role: "system", content: this.currentTask.systemRole?.trim()})
            }
            return context;
        },

        stopTask() {
            this.chatsStore.stopTasks();
            let changed = false;
            for (const msg of this.currentTask.messages) {
                if (msg.status === 'updating') {
                    msg.status = null;
                    changed = true;
                }
            }
            if (changed) {
                this.chatsStore.updateChat(this.currentTask);
            }
        },

        getTaskInfo(requestingTask, msgIdx, rerun = false) {
            const message = requestingTask.messages[msgIdx];
            if (rerun) {
                message.content = null;
                message.response = null;
                message.rendered_response = null;
            }

            message.content = message.content ?? this.formatInput(message.input_variables);
            const prompt = this.formatPrompt(message.content);
            return {
                msgIdx: msgIdx,
                prompt: prompt,
                taskId: this.taskId,
                message: message,
            }
        },

        startTask(rerun = false) {
            let userInputIsValid = true;
            const taskVariableValues = this.taskVariableValues;
            const taskVariables = this.taskVariables;
            for (const taskVariable of taskVariables) {
                if (!taskVariableValues[taskVariable]) {
                    userInputIsValid = false;
                    break
                }
            }
            this.requestingTaskId = this.taskId; // Store the chat ID when the request starts
            const requestingTask = this.requestingTask;

            const taskList = [];
            if (userInputIsValid) {
                const formatInput = this.formatInput(this.taskVariableValues);
                const prompt = this.formatPrompt(formatInput);
                const message = {
                    input_variables: {...this.taskVariableValues},
                    content: formatInput,
                    response: null,
                    rendered_response: null,
                    role: 'user+assistant',
                }
                requestingTask.messages.push(message);

                taskList.push({
                    msgIdx: requestingTask.messages.length - 1,
                    prompt: prompt,
                    taskId: this.taskId,
                    message: message,
                })
            }

            for (let msgIdx = 0; msgIdx < requestingTask.messages.length; msgIdx++) {
                const message = requestingTask.messages[msgIdx];
                if (!message.response || rerun) {
                    taskList.push(this.getTaskInfo(requestingTask, msgIdx, rerun))
                }
            }
            this.chatsStore.startTasks(taskList);
        },

        rerunTask(idx) {
            if (this.isLoadingTaskRequest) {
                this.$toast.error("Please wait for the current request to finish");
                return;
            }
            this.requestingTaskId = this.taskId; // Store the chat ID when the request starts
            this.chatsStore.startTasks(this.getTaskInfo(this.requestingTask, idx, true));
        },


        copyContent(text) {
            copyToClipboard(text, this.$toast);
        },

        onTaskStart(taskInfo) {
            const msgIdx = taskInfo.msgIdx;
            console.log('Start task: ', taskInfo.msgIdx);
            if (this.isLoadingTaskRequest && this.taskId === this.requestingTaskId) {
                this.scrollMessageIntoView(msgIdx);
                const message = this.requestingTask.messages[msgIdx];
                const filename = message.filename ?? '';
                if (this.$refs.statusTextRef) {
                    this.$refs.statusTextRef.innerText = `Requesting ${filename}... , ${msgIdx + 1}/${this.requestingTask.messages.length}`;
                }
            }
        },
        // onTaskFinish(taskInfo) {
        //     console.log('Finish task: ', taskInfo.msgIdx);
        // },

        scrollToBottom(force) {
            this.$nextTick(() => {
                const target = this.$refs.messagesContainerRef;
                this.scrollToBottomController.toBottom(target, force);
            });
        },

        deleteAllMessages() {
            if (confirm("Are you sure you want to delete all messages?")) {
                this.chatsStore.updateChat({messages: []}, this.taskId);
            }
        },


        deleteMessage(index) {
            if (this.isLoadingTaskRequest) {
                this.$toast.error("Please wait for the current request to finish");
                return;
            }
            this.currentTask.messages.splice(index, 1);
            this.chatsStore.saveChat(this.currentTask);
        },

        createTaskFromFile(fileData, metaData) {
            for (const filename in fileData) {
                console.log(filename, fileData[filename].length);
                for (const split of fileData[filename]) {
                    // TODO: handle variables replacement
                    const formatPrompt = this.currentTask.taskPrompt.replace(this.taskVariablesRegex, function () {
                        return split ?? '';
                    });
                    this.currentTask.messages.push({
                        input_variables: split,
                        filename: filename,
                        content: formatPrompt,
                        response: null,
                        rendered_response: null,
                        role: 'user+assistant',
                    });
                }
            }
            this.currentTask.metaData = metaData;
            this.chatsStore.saveChat(this.currentTask);
            this.$toast.success(`Tasks created from files`);
        },
    }
}
</script>

<style>
.markdown-body p:not(:last-child) {
    margin-bottom: 1rem;
}
</style>