<template>
  <BaseModal title="Prompts" :show="show" @close="$emit('close')" width="max-w-6xl" position="absolute">
    <template #header>
      <ul class="flex font-medium text-center text-gray-500 dark:text-gray-400 overflow-x-auto">
        <li class="mr-2 whitespace-nowrap">
          <button @click="showSection='custom'" :class="{'text-gray-800 border-gray-300 dark:text-gray-200': showSection==='custom', 'border-transparent': showSection!=='custom'}"
                  class="inline-flex items-center border-b-2 p-2 rounded-t-lg hover:text-gray-800 hover:border-gray-300 dark:hover:text-gray-200 group">
            <PuzzlePieceIcon class="w-5 h-5 mr-2"/>
            Custom
          </button>
        </li>
        <li class="mr-2 whitespace-nowrap">
          <button @click="showSection='workshop'" :class="{'text-gray-800 border-gray-300 dark:text-gray-200': showSection==='workshop', 'border-transparent': showSection!=='workshop'}"
                  class="inline-flex items-center border-b-2 p-2 rounded-t-lg hover:text-gray-800 hover:border-gray-300 dark:hover:text-gray-200 group">
            <QrCodeIcon class="w-5 h-5 mr-2"/>
            Workshop
          </button>
        </li>
        <li class="whitespace-nowrap">
          <button @click="showSection='workshop_cn'" :class="{'text-gray-800 border-gray-300 dark:text-gray-200': showSection==='workshop_cn', 'border-transparent': showSection!=='workshop_cn'}"
                  class="inline-flex items-center border-b-2 p-2 rounded-t-lg hover:text-gray-800 hover:border-gray-300 dark:hover:text-gray-200 group">
            <QrCodeIcon class="w-5 h-5 mr-2"/>
            Workshop 中文
          </button>
        </li>
      </ul>
    </template>
    <div class="overflow-y-auto p-2 min-h-[15rem]">
      <div v-show="showSection==='custom' || !isLoadingPrompts" class="grid grid-flow-row grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-5">
        <div @click="createPrompt()" v-show="showSection==='custom'"
             class="cursor-pointer p-5 bg-white border-2 border-dashed hover:border-solid border-slate-300 rounded-md shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-400 dark:hover:bg-gray-900">
          <div class="flex min-h-[100px] w-full flex-col items-center font-bold tracking-tight text-gray-900 dark:text-white">
            <PlusIcon class="w-8 h-8 mt-5"/>
            <span class="text-xl mt-1">New Prompt</span>
          </div>
        </div>
        <div v-for="(prompt, index) in currentPrompts" :key="index" @click="createPrompt(prompt, index)"
             :class="[prompt.color ? prompt.color : 'bg-amber-500']"
             class="cursor-pointer flex flex-col p-3 border border-gray-200 rounded-md shadow hover:bg-opacity-75 dark:border-gray-700">
          <h3 class="mb-2 text-2xl font-bold tracking-tight text-white">{{ prompt.name }}</h3>
          <div class="flex flex-col flex-grow justify-between">
            <p class="font-normal text-gray-100 line-clamp-5">{{ prompt.prompt }}</p>
            <div class="mt-2">
              <button v-if="showSection==='custom'"
                      @click.stop="deletePrompt(index)"
                      class="inline-block outline-none border-red-300 bg-red-200 hover:border-red-400 hover:bg-red-300 border-2 rounded-full px-2 py-1 text-xs font-semibold text-red-900 mr-2 mb-2">
                Delete
              </button>
              <button v-if="showSection!=='custom'"
                      @click.stop="addPrompt(prompt)"
                      class="inline-block outline-none border-green-300 bg-green-200 hover:border-green-400 hover:bg-green-300 border-2 rounded-full px-2 py-1 text-xs font-semibold text-green-900 mr-2 mb-2">
                Add
              </button>
              <button v-if="showSection!=='custom'"
                      @click.stop="$emit('create-task', {title: prompt.name, taskPrompt: prompt.prompt})"
                      class="inline-block outline-none border-purple-300 bg-purple-200 hover:border-purple-400 hover:bg-purple-300 border-2 rounded-full px-2 py-1 text-xs font-semibold text-purple-900 mr-2 mb-2">
                As task
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSection!=='custom' && isLoadingPrompts" class="flex justify-center">
        <LoadingIcon class="h-5 w-10 m-5"/>
      </div>
    </div>
  </BaseModal>
  <PromptModal :name="currentName" :act="currentAct" :prompt="currentPrompt" :promptId="currentIdx"
               :show="showPromptModal" @close="showPromptModal = false"/>
</template>
<script setup>
import {
  PlusIcon, PuzzlePieceIcon, QrCodeIcon
} from '@heroicons/vue/24/solid';
import LoadingIcon from "@/assets/icons/LoadingIcon.vue";
</script>
<script>
import {useSettingsStore} from "@/stores/settings";
import {useChatsStore} from "@/stores/chat";
import {mapWritableState} from "pinia";
import Papa from "papaparse"
// import {fetchPrompts} from "@/scripts/Utils";
import BaseModal from "./BaseModal.vue";
import PromptModal from "./PromptModal.vue";

export default {
  props: ['show'],
  emits: ['close', 'create-task'],
  components: [
    BaseModal,
    PromptModal,
  ],
  data() {
    const settingStore = useSettingsStore();
    const chatsStore = useChatsStore();
    return {
      settingStore: settingStore,
      chatsStore: chatsStore,
      onlinePrompts: [],
      chinesePrompts: [],
      showPromptModal: false,

      showSection: 'custom',
      isLoadingPrompts: true,
      currentName: null,
      currentAct: null,
      currentPrompt: null,
      currentIdx: null,
    };
  },
  computed: {
    currentPrompts() {
      if (this.showSection === 'workshop') {
        if (this.onlinePrompts.length === 0) {
          Papa.parse('https://raw.githubusercontent.com/f/awesome-chatgpt-prompts/main/prompts.csv', {
            header: true,
            download: true,
            complete: (results) => {
              results.data.forEach(item => {
                if (item.act && item.prompt) {
                  this.onlinePrompts.push({
                    name: item.act, prompt: item.prompt, color: this.chatsStore.randomColor(),
                  });
                }
              })
              this.isLoadingPrompts = false;
            }
          });
        }
        return this.onlinePrompts;
      } else if (this.showSection === 'workshop_cn') {
        if (this.chinesePrompts.length === 0) {
          fetch("https://raw.githubusercontent.com/PlexPt/awesome-chatgpt-prompts-zh/main/prompts-zh.json").then(async (response) => {
            let res = await response.json();
            for (let i = 0; i < res.length; i++) {
              let name = res[i].act.trim();
              let prompt = res[i].prompt.trim();
              this.chinesePrompts.push({
                name: name, prompt: prompt, color: this.chatsStore.randomColor(),
              });
            }
            this.isLoadingPrompts = false;
          }).catch();
        }
        return this.chinesePrompts;
      }
      return this.prompts;
    },
    ...mapWritableState(useChatsStore, ['prompts']),
  },
  mounted() {
    // fetchPrompts(this.prompts);
  },
  methods: {
    createPrompt(prompt, idx) {
      if (prompt === undefined) {
        this.currentName = null;
        this.currentAct = null;
        this.currentPrompt = null;
        this.currentIdx = undefined;
      } else {
        this.currentName = prompt.name;
        this.currentAct = prompt.act;
        this.currentPrompt = prompt.prompt;
        this.currentIdx = this.showSection !== 'custom' ? undefined : idx;
      }
      this.showPromptModal = true;
    },
    deletePrompt(index) {
      this.chatsStore.prompts.splice(index, 1);
      this.chatsStore.savePrompt();
    },
    async addPrompt(prompt) {
      await this.chatsStore.createPrompt(prompt.name, prompt.act, prompt.prompt);
      this.$toast.success('Added!');
    }
  }
}
</script>
