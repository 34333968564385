<template>
  <div class="group w-full text-gray-800 dark:text-gray-100 border-b border-black/10 dark:border-gray-900/50"
       :class="{ 'dark:bg-gray-800': message.role === 'user', 'bg-gray-50 dark:bg-[#444654]':message.role === 'assistant'}">
    <div class="message text-base gap-4 md:gap-6 md:max-w-2xl lg:max-w-xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0 m-auto">
      <div class="w-[32px] flex flex-col relative items-end">
        <div class="relative h-[32px] w-[32px] rounded-sm flex items-center justify-center">
          <OpenAiIcon v-if="message.role === 'assistant'" class="h-[32px] w-[32px]"/>
          <img class="rounded-md" v-else-if="avatar" :src="avatar">
          <div v-else class="h-[32px] w-[32px] overflow-hidden bg-gray-200 rounded-md dark:bg-gray-500">
          </div>
        </div>
      </div>
      <div ref="messageBodyRef" class="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
        <div class="flex flex-grow flex-col gap-3">
          <LoadingIcon class="h-5 w-10" v-if="showLoadingAnimation"/>
          <div v-if="message.role === 'assistant'" v-html="message.rendered_content" class="markdown-body"></div>
          <div v-else class="user-message whitespace-pre-wrap outline-0">
            {{ message.content }}
          </div>
          <div v-if="isEditing && message.role === 'user'" class="text-center mt-2 flex justify-center">
            <button @click="submitEdit()"
                    class="py-1.5 px-2.5 text-xs font-medium text-center text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mr-2">
              Save &amp; Submit
            </button>
            <button @click="cancelEdit()"
                    class="py-1.5 px-2.5 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
              Cancel
            </button>
          </div>
        </div>
        <div class="text-gray-400 flex self-end lg:self-center justify-center mt-2 gap-2 md:gap-3 lg:gap-1 lg:absolute lg:top-0 lg:translate-x-full lg:right-0 lg:mt-0 lg:pl-2 visible">
          <button v-if="message.role === 'user'" @click="startEditContent()" title="Edit"
                  class="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible">
            <PencilSquareIcon class="h-4 w-4"/>
          </button>
          <button v-if="message.role === 'assistant'" @click="$emit('re-generate-response', idx)" title="Regenerate"
                  class="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible">
            <ArrowPathIcon class="h-4 w-4"/>
          </button>
          <button @click="copyContent(message.content)" title="Copy"
                  class="p-1 rounded-md hover:bg-gray-100 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible">
            <DocumentDuplicateIcon class="h-4 w-4"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {DocumentDuplicateIcon, PencilSquareIcon, ArrowPathIcon} from '@heroicons/vue/24/solid';
import OpenAiIcon from "@/assets/icons/OpenAiIcon.vue";
import LoadingIcon from "@/assets/icons/LoadingIcon.vue";
</script>

<script>

import {copyToClipboard} from "@/scripts/Utils";

export default {
  // components: {LoadingIcon},
  props: {
    message: {
      type: Object,
      required: true,
    },
    avatar: String,
    idx: null,
  },
  data() {
    return {
      isEditing: false
    }
  },

  mounted() {
    this.addCodeBlockCopyListener();
  },
  watch: {
    message: {
      handler() {
        this.$nextTick(() => {
          this.addCodeBlockCopyListener();
        });
      },
      deep: true,
    },

  },
  computed: {
    showLoadingAnimation() {
      return this.message.role === 'assistant' && !this.message.rendered_content && !['error', 'canceled'].includes(this.message.status);
    },
  },
  methods: {
    addCodeBlockCopyListener() {
      this.$refs.messageBodyRef.querySelectorAll('pre').forEach((node) => {
        const btn = node.children[0].querySelector('button');
        btn.onclick = () => {
          copyToClipboard(node.children[1].innerText, this.$toast);
        };
      })
    },

    copyContent(text) {
      copyToClipboard(text, this.$toast);
    },
    startEditContent() {
      this.isEditing = true;
      const el = this.$refs.messageBodyRef.querySelector('.user-message');
      el.contentEditable = true;
      el.focus();
    },
    cancelEdit() {
      this.isEditing = false;
      const el = this.$refs.messageBodyRef.querySelector('.user-message');
      el.innerText = this.message.content;
      el.contentEditable = false;
    },
    submitEdit() {
      const el = this.$refs.messageBodyRef.querySelector('.user-message');
      const content = el.innerText.trim();
      if (content) {
        this.$emit('edit-user-message', this.idx, content);
        this.cancelEdit();
      }
    }
  }
};
</script>