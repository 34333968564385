import {createApp} from "vue";
import ToastComponent from "./ToastPlugin.vue";

const Toast = {
    install: (app) => {
        const toastContainer = document.createElement("div");
        toastContainer.classList.add(
            "fixed",
            "inset-x-0",
            "top-0",
            "w-full",
            "z-50",
            "flex",
            "flex-col",
            "items-center",
        );
        toastContainer.style.pointerEvents = "none";
        document.body.appendChild(toastContainer);
        const toast = (content, status) => {
            const toastInstance = createApp(ToastComponent, {
                content: content,
                status: status,
            });

            const toastEl = document.createElement("div");

            if (toastContainer.firstChild) {
                toastContainer.insertBefore(toastEl, toastContainer.firstChild);
            } else {
                toastContainer.appendChild(toastEl);
            }
            toastInstance.mount(toastEl);

            const closeButton = toastEl.querySelector('button');
            closeButton.addEventListener('click', () => {
                toastEl.remove();
            });
            setTimeout(() => {
                if (toastContainer.contains(toastEl)) {
                    toastContainer.removeChild(toastEl);
                }
            }, 2000);
        };

        app.config.globalProperties.$toast = {
            success(content) {
                toast(content, 'success');
            },
            error(content) {
                toast(content, 'error');
            },
        }

    },
};

export default Toast;
