<template>
  <BaseModal title="Global Settings" :show="show">
    <div class="mb-4">
      <label for="userAvatar" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Avatar</label>
      <input type="text" id="userAvatar" v-model="avatar"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    </div>
    <div class="mb-4">
      <label for="apiHost" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">API Host</label>
      <input type="text" id="apiHost" v-model="apiHost"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             placeholder="https://api.openai.com/">
    </div>
    <div class="mb-4">
      <label for="openaiKey" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">OpenAI Key</label>
      <input v-bind:type="openaiKeyFocused ? 'text' : 'password'" id="openaiKey" v-model="openaiKey"
             @focus="openaiKeyFocused=true" @blur="openaiKeyFocused=false"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             placeholder="sk-***">
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal.vue";
import {mapWritableState} from "pinia";
import {useSettingsStore} from "@/stores/settings";

export default {
  components: {BaseModal},
  props: {
    show: Boolean,
  },
  data() {
    return {
      modal: null,
      openaiKeyFocused: false,
    };
  },
  mounted() {
    const store = useSettingsStore();
    store.load();
    for (const name of ['avatar', 'apiHost', 'openaiKey']) {
      this.$watch(name, () => {
        localStorage.setItem("globalSettings", JSON.stringify(store.$state));
      })
    }
  },
  computed: {
    ...mapWritableState(useSettingsStore, ['avatar', 'apiHost', 'openaiKey'])
  },
}
</script>