<template>
  <div style="pointer-events: auto" class="my-2 flex items-center w-[20rem] max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow-2xl border border-gray-100 dark:border-gray-900 dark:text-gray-400 dark:bg-gray-800" role="alert">
    <div :class="{
          'text-green-500 bg-green-100 dark:bg-green-800 dark:text-green-200':status === 'success',
          'text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200':status === 'error',
      }" class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg">
      <XMarkIcon v-if="status === 'error'" class="w-5 h-5"/>
      <CheckIcon v-else-if="status === 'success'" class="w-5 h-5"/>
      <span class="sr-only">Check icon</span>
    </div>
    <div class="ml-3 text-sm font-normal">{{ content }}</div>
    <button type="button" aria-label="Close" class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">
      <span class="sr-only">Close</span>
      <XMarkIcon class="w-5 h-5"/>
    </button>
  </div>
</template>
<script setup>
import {
  CheckIcon, XMarkIcon
} from '@heroicons/vue/24/solid';
</script>
<script>

export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: 'success',
    },
  },
};
</script>
