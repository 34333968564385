import {defineStore} from 'pinia'
import {getNonArrayProps} from "@/scripts/Utils";
import {requestCompletion} from "@/scripts/OpenAIRequest";
import {renderMarkdown} from "@/scripts/MessageRender";
import {useSettingsStore} from "@/stores/settings";

export const useChatsStore = defineStore('chats', {
    state: () => {
        return {
            chats: [],
            prompts: [],
            selectedChatId: null,
            loadingRequest: false,
            requestingChatId: null,
            controller: null, //chat request controller

            taskRequestController: null, //task request controller
            isLoadingTaskRequest: false,
            requestingTaskId: null,
            taskList: [],
            shouldStopTask: false,
            taskRunEventListeners: [],
            settingStore: useSettingsStore(),
        }
    },
    getters: {
        currentChat() {
            return this.getChat(this.selectedChatId);
        },
        requestingChat() {
            return this.getChat(this.requestingChatId);
        },
        requestingTask() {
            return this.getChat(this.requestingTaskId);
        },
    },

    actions: {
        addTaskEventListener(listener) {
            this.taskRunEventListeners.push(listener);
        },
        removeTaskEventListener(listener) {
            if (this.taskRunEventListeners.includes(listener)) {
                this.taskRunEventListeners.splice(this.taskRunEventListeners.indexOf(listener), 1);
            }
        },
        stopTasks() {
            this.shouldStopTask = true;
            if (this.taskRequestController) {
                this.taskRequestController.abort();
            }
            this.isLoadingTaskRequest = false;
        },
        async startTasks(taskList) {
            if (!this.isLoadingTaskRequest) {
                this.taskList.splice(0, this.taskList.length, ...taskList);
                for (const taskInfo of taskList) {
                    if (!this.shouldStopTask) {
                        await this.doTaskWithRetry(taskInfo);
                    }
                }
                this.isLoadingTaskRequest = false;
            }
        },

        async doTaskWithRetry(taskInfo, waitTime = 5000) {
            await this.doTask(taskInfo, true);
            while (!this.shouldStopTask && taskInfo.message.status === 'error') {
                console.log('error, retrying in 5 seconds');
                await new Promise(resolve => setTimeout(resolve, waitTime));
                await this.doTask(taskInfo, true);
            }
        },

        async doTask(taskInfo, willRetry = false) {
            if (this.isLoadingTaskRequest && !willRetry) return;
            this.isLoadingTaskRequest = true;
            const prompt = taskInfo.prompt;
            const taskId = taskInfo.taskId;
            const message = taskInfo.message;

            const requestingTask = this.getChat(taskId);
            requestingTask.status = 'requesting';
            message.status = 'started';
            for (const listener of this.taskRunEventListeners) {
                if (listener?.onTaskStart)
                    listener.onTaskStart(taskInfo);
            }

            this.taskRequestController = new AbortController();
            await requestCompletion(
                prompt,
                {
                    openaiKey: requestingTask.openaiKey ? requestingTask.openaiKey : this.settingStore.openaiKey,
                    apiHost: requestingTask.apiHost ? requestingTask.apiHost : this.settingStore.apiHost,
                    model: requestingTask.model,
                    temperature: requestingTask.temperature,
                    stream: true,
                    onUpdate: (newContent) => {
                        message.response = newContent;
                        message.rendered_response = renderMarkdown(newContent);
                        message.status = 'updating';
                    },
                    onFinish: (newContent) => {
                        message.status = 'finished';
                        message.response = newContent;
                        message.rendered_response = renderMarkdown(newContent);
                        this.saveChat(requestingTask);
                        for (const listener of this.taskRunEventListeners) {
                            if (listener?.onTaskFinish)
                                listener.onTaskFinish(taskInfo);
                        }
                        if (!willRetry) {
                            this.isLoadingTaskRequest = false;
                            requestingTask.status = null;
                        }
                    },
                    onError: (err) => {
                        if (err.name === 'AbortError') {
                            message.status = 'canceled';
                            console.log('Request was cancelled by the user');
                            this.shouldStopTask = true;
                        } else {
                            message.status = 'error';
                            console.log('err message:', err.message);
                            if (!willRetry) {
                                console.log('Request failed', err);
                                message.rendered_response = `<span class="text-red-500">${err.message}</span>`;
                                this.$toast.error(err.message);
                            }
                        }
                        if (!willRetry) {
                            this.isLoadingTaskRequest = false;
                            requestingTask.status = null;
                        }
                    },
                },
                this.taskRequestController,
            );

        },

        getRandomElement(arr) {
            const randomIndex = Math.floor(Math.random() * arr.length);
            return arr[randomIndex];
        },
        randomColor() {
            const colors = ['slate', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'indigo', 'violet', 'purple', 'fuchsia'];
            const weights = ['500', '600', '700', '800'];
            const color = this.getRandomElement(colors);
            const weight = this.getRandomElement(weights);
            return 'bg-' + color + '-' + weight;
        },
        async createPrompt(name, act, prompt) {
            const res = {
                name: name,
                act: act,
                prompt: prompt,
                color: this.randomColor(),
            };
            this.prompts.unshift(res)
            await this.savePrompt();
            return res;
        },
        async savePrompt() {
            localStorage.setItem('prompts', JSON.stringify(this.prompts));
        },
        async loadPrompt() {
            const prompts = JSON.parse(localStorage.getItem('prompts'));
            if (prompts) {
                this.prompts = prompts;
            }
        },
        getChat(chatId) {
            return this.chats.find(chat => chat.id === chatId);
        },

        create(select = true, params) {
            const timestamp = new Date();
            const chatId = timestamp.getTime();
            params = params ?? {}
            const newChat = {
                id: chatId,
                title: 'New Chat',
                type: 'chat',
                userInput: "",
                timestamp: timestamp,
                messages: [],
                model: 'gpt-3.5-turbo',
                contextLength: 20,
                temperature: 0.7,
                systemRole: null,
                taskPrompt: null,
                apiHost: null,
                openaiKey: null,
                ...params,
            };

            this.chats.unshift(newChat);
            if (select) {
                this.selectedChatId = newChat.id;
            }
            this.saveChat(newChat);
            return newChat;
        },
        async saveChat(chat) {
            localStorage.setItem(`chat-${chat.id}`, JSON.stringify(chat));
            await this.saveChatSettings(chat);
        },

        async saveChatSettings(chat) {
            const chatSettings = {};
            for (const key in chat) {
                if (!Array.isArray(chat[key])) {
                    chatSettings[key] = chat[key];
                }
            }
            localStorage.setItem(`chatSettings-${chat.id}`, JSON.stringify(chatSettings));
        },

        async deleteChat(chatId) {
            localStorage.removeItem(`chat-${chatId}`);
            localStorage.removeItem(`chatSettings-${chatId}`);
        },
        async updateChat(data, chatId = undefined) {
            const currentChat = chatId ? this.getChat(chatId) : this.currentChat;
            for (const key in data) {
                currentChat[key] = data[key]
            }
            await this.saveChat(currentChat);
        },

        async updateChatSettings(newSettings, chatId = undefined) {
            const currentChat = chatId ? this.getChat(chatId) : this.currentChat;
            const currentChatForSave = getNonArrayProps(currentChat);
            for (const key in newSettings) {
                if (!Array.isArray(newSettings[key])) {
                    currentChat[key] = newSettings[key]
                    currentChatForSave[key] = newSettings[key]
                }
            }
            await this.saveChatSettings(currentChatForSave);
        },
        async load() {
            this.chats.splice(0, this.chats.length);
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);

                if (key.startsWith("chat-")) {
                    const chat = JSON.parse(localStorage.getItem(key));
                    let chatSettings = localStorage.getItem(`chatSettings-${key.slice(5)}`);
                    if (chatSettings) {
                        chatSettings = JSON.parse(chatSettings);
                        for (const key in chatSettings) {
                            if (!Array.isArray(chat[key])) {
                                chat[key] = chatSettings[key];
                            }
                        }
                    }
                    chat.status = null; // reset status
                    this.chats.push(chat);
                }
            }
            this.chats.sort((a, b) => b.id - a.id);
            const storedSelectedChatId = localStorage.getItem("selectedChatId");
            if (storedSelectedChatId !== null && this.chats.find(chat => chat.id === Number(storedSelectedChatId))) {
                this.selectedChatId = Number(storedSelectedChatId);
            } else if (this.chats.length > 0) {
                this.selectedChatId = this.chats[0].id;
            }
            await this.loadPrompt();
        }
    },
})