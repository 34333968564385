import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark.css'; // 导入你选择的样式
import MarkdownItTexMath from "markdown-it-texmath";
import "markdown-it-texmath/css/texmath.css"; // 引入样式
import "katex/dist/katex.min.css"; // 引入katex样式
import katex from "katex";
import markdownitLinkAttributes from "markdown-it-link-attributes"

const md = new MarkdownIt({
    linkify: true
});
const enhanceCode = (tokens, idx) => {
    const token = tokens[idx];
    const language = token.info.trim() || '';
    const content = token.content;
    const extraDiv = [`<div class="flex items-center relative text-gray-300 bg-gray-800 px-4 py-2 text-xs font-sans justify-between rounded-t-md"><span>${language}</span>`,
        `<button class="flex ml-auto gap-2 hover:text-gray-50"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">`,
        `<path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>`,
        `<rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>`,
        `</svg>Copy code</button>`,
        `</div>`];
    const highlightedContent = hljs.highlightAuto(content, language ? [language] : undefined).value;
    const wrappedContent = `${extraDiv.join('')}<div class="p-4 overflow-auto"><code class="language-${language}">${highlightedContent}</code></div>`
    return `<pre class="bg-black text-white text-sm leading-6 rounded-md my-4">${wrappedContent}</pre>`;
}
md.renderer.rules.code_block = enhanceCode;
md.renderer.rules.fence = enhanceCode;

md.use(MarkdownItTexMath, {engine: katex, delimiters: "dollars", katexOptions: {macros: {"\\RR": "\\mathbb{R}"}}})
    .use(markdownitLinkAttributes, {attrs: {target: "_blank", rel: "noopener"}});


export function renderMarkdown(content) {
    return md.render(content);
}