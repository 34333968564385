import {defineStore} from 'pinia'

export const useSettingsStore = defineStore('settings', {
    state: () => {
        return {
            avatar: '',
            apiHost: 'https://api.openai.com/',
            openaiKey: '',
        }
    },
    actions: {
        load() {
            let globalSettings = JSON.parse(localStorage.getItem("globalSettings"));
            if (globalSettings) {
                this.$patch({...this.globalSettings, ...globalSettings});
            }
        }
    },
})