<template>
  <BaseModal title="New Task" :show="show">
    <div class="mb-4">
      <label for="taskName" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Name</label>
      <input type="text" id="taskName" v-model="task.title"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    </div>
    <!--    <div class="mb-4">-->
    <!--      <label for="systemRole" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">System Role</label>-->
    <!--      <textarea id="systemRole" rows="2" v-model.trim="task.systemRole"-->
    <!--                class="block p-2.5 w-full resize-y text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"-->
    <!--                placeholder="System role"></textarea>-->

    <!--    </div>-->
    <div class="mb-4">
      <label for="taskPrompt" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Task Prompt</label>
      <AutosizeTextarea id="taskPrompt" rows="3" v-model.trim="task.taskPrompt"
                        class="block p-2.5 w-full resize-y text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="e.g., Please translate the following sentence to Chinese: {{content}}}"></AutosizeTextarea>
    </div>
    <template #footer>
      <div class="flex items-center px-6 pb-6 rounded-b">
        <button type="button" @click="createTask()" class="inline-flex justify-center rounded-md text-sm font-semibold py-2 px-3 bg-slate-900 text-white hover:bg-slate-800 hover:bg-opacity-80 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:focus:ring-slate-800">Create</button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import AutosizeTextarea from "@/components/ui/AutosizeTextarea.vue";

export default {
  components: {BaseModal, AutosizeTextarea},
  props: {
    show: Boolean,
    taskData: Object,
  },
  data() {
    return {
      task: {
        title: 'New Task',
        taskPrompt: null,
        // systemRole: null,
        files: [],
        ...this.taskData,
      },
    };
  },

  watch: {
    taskData() {
      this.task = {...this.task, ...this.taskData}
    }
  },
  methods: {
    createTask() {
      if (!this.task.taskPrompt) {
        this.$toast.error('Please enter task prompt.');
        return
      }
      this.$emit('create-task', this.task);
      this.$emit('close')
    },
  },
}
</script>