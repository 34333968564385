<template>
  <div class="chat-app flex h-screen">
    <ChatSidebar :showSidebar="showSidebar"
                 :dataLoaded="dataLoaded"
                 @close="showSidebar=false"
                 @manage-prompts="showPromptsSetting=true"
                 @create-new-task="showNewTaskModal=true"/>
    <main class="flex h-full max-w-full flex-1 flex-col dark:bg-gray-800">
      <div class="flex h-full max-w-full flex-1 flex-col items-center" v-show="!dataLoaded">
        <LoadingIcon class="h-5 w-10 mt-16"/>
      </div>

      <div class="flex h-full max-w-full flex-1 flex-col" v-if="dataLoaded">
        <div class="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
          <ChatSetting v-if="currentChat" :show="showChatSettingModal" @close="showChatSettingModal = false" :currentChat="currentChat"/>
          <PromptManager :show="showPromptsSetting" @close="showPromptsSetting = false" @create-task="data=>{taskData=data; showNewTaskModal=true}"/>
          <div class="flex h-full max-w-full flex-1 flex-col">
            <div :class="{'md:hidden': !currentChat}"
                 class="flex w-full py-1.5 px-3 justify-between items-center md:justify-center bg-white border-b border-black/10 dark:border-gray-900/50 dark:bg-gray-900 dark:text-gray-100">
              <button type="button" @click="showSidebar = true"
                      class="md:hidden p-0.5 inline-flex items-center justify-center rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 focus:outline-none dark:hover:text-white">
                <span class="sr-only">Open sidebar</span>
                <Bars3Icon class="h-6 w-6"/>
              </button>
              <button v-if="currentChat" @click="showChatSettingModal=!showChatSettingModal"
                      class="inline-flex justify-center items-center rounded px-2 py-0.5 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                <AdjustmentsHorizontalIcon class="w-4 h-4 mr-2"/>
                {{ currentChat?.model }}
              </button>
              <button @click="chatsStore.create()" type="button"
                      class="md:hidden p-0.5 inline-flex items-center justify-center rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 focus:outline-none dark:hover:text-white">
                <PlusIcon class="h-6 w-6"/>
              </button>
            </div>

            <div v-if="!currentChat" class="flex flex-col items-center text-sm dark:bg-gray-800">
              <div class="text-gray-800 w-full md:max-w-2xl lg:max-w-3xl md:h-full md:flex md:flex-col px-6 dark:text-gray-100">
                <h1 v-if="!currentChat?.messages?.length"
                    class="text-4xl font-semibold text-center mt-6 sm:mt-[20vh] ml-auto mr-auto mb-10 sm:mb-16 flex gap-2 items-center justify-center">
                  ChatGPT<span class="bg-yellow-200 text-yellow-900 py-0.5 px-1.5 text-xs md:text-sm rounded-md uppercase">Vue</span>
                </h1>
                <div class="md:flex items-start text-center gap-3.5">
                  <div class="flex mb-8 md:mb-auto flex-1">
                    <button @click="chatsStore.create()" class="text-slate-500 dark:text-slate-300 hover:text-slate-600 dark:hover:text-slate-100 dark:hover:border-slate-300 hover:border-slate-400 hover:border-solid group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 leading-6 font-medium py-3">
                      <ChatBubbleBottomCenterTextIcon class="w-5 h-5"/>
                      New chat
                    </button>
                  </div>
                  <div class="flex mb-8 md:mb-auto flex-1">
                    <button @click="showNewTaskModal=true" class="text-slate-500 dark:text-slate-300 hover:text-slate-600 dark:hover:text-slate-100 dark:hover:border-slate-300 hover:border-slate-400 hover:border-solid group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 leading-6 font-medium py-3">
                      <Square3Stack3DIcon class="w-5 h-5"/>
                      New task
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <TaskWindow v-if="currentChat?.type ==='task'" :taskId="selectedChatId"/>
            <ChatWindow v-else-if="currentChat?.type ==='chat'" :chatId="selectedChatId" @create-prompt="showPromptsSetting = true"/>
          </div>
        </div>
      </div>
    </main>
  </div>
  <NewTaskModal :show="showNewTaskModal" @close="showNewTaskModal = false" :taskData="taskData" @create-task="createTask"/>
</template>

<script setup>
import {
  AdjustmentsHorizontalIcon,
  Bars3Icon,
  ChatBubbleBottomCenterTextIcon,
  PlusIcon,
  Square3Stack3DIcon,
} from '@heroicons/vue/24/solid';
import LoadingIcon from "@/assets/icons/LoadingIcon.vue";
import NewTaskModal from "./modals/NewTaskModal.vue";


</script>

<script>
import ChatSetting from "./modals/ChatSetting.vue";
import ChatSidebar from "./ChatSidebar.vue";
import ChatWindow from "./ChatWindow.vue";
import TaskWindow from "./TaskWindow.vue";
import PromptManager from "./modals/PromptManager.vue";

import {mapState} from 'pinia'
import {mapWritableState} from 'pinia'

import {useChatsStore} from '@/stores/chat'

export default {
  components: {
    ChatSetting,
    TaskWindow,
    ChatWindow,
    PromptManager,
    ChatSidebar
  },

  data() {
    const chatsStore = useChatsStore();
    return {
      chatsStore: chatsStore,
      dataLoaded: false,
      showPromptsSetting: false,

      showSidebar: false,
      showChatSettingModal: false,
      showNewTaskModal: false,
      taskData: null,
    };
  },
  async mounted() {
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    await this.chatsStore.load();
    this.dataLoaded = true;
  },

  computed: {
    ...mapState(useChatsStore, ['chats', 'currentChat']),
    ...mapWritableState(useChatsStore, ['selectedChatId']),
  },
  methods: {
    createTask(taskData) {
      this.chatsStore.create(true, {
        title: 'New Task',
        type: 'task',
        ...taskData,
      });
    },
  },
}
</script>
