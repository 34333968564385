<template>
  <div v-if="showSidebar"
       @click="$emit('close')"
       class="fixed inset-0 z-10"
  ></div>
  <nav class="dark bg-gray-900 md:flex w-[260px] md:flex-col md:translate-x-0"
       :class="{
        'translate-x-full hidden': !showSidebar,
        'fixed z-50 inset-y-0': showSidebar,
      }">
    <div class="flex h-full min-h-0 flex-col ">
      <div class="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
        <div class="flex h-full flex-1 flex-col space-y-1 p-2">
          <div class="flex flex-col-2 gap-1.5 justify-around">
            <button @click="createChat()" class="flex p-2 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-gray-200 hover:text-white cursor-pointer text-sm mb-2 flex-shrink-0 border border-white/20">
              <PlusIcon class="h-4 w-4 stroke-2"></PlusIcon>
              New chat
            </button>
            <button @click="$emit('create-new-task')" class="flex p-2 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-gray-200 hover:text-white cursor-pointer text-sm mb-2 flex-shrink-0 border border-white/20">
              <Square3Stack3DIcon class="h-4 w-4 stroke-2"></Square3Stack3DIcon>
              New task
            </button>
          </div>
          <div class="flex-col flex-1 overflow-y-auto border-b border-white/20">
            <div class="flex h-full max-w-full flex-1 flex-col items-center" v-show="!dataLoaded">
              <LoadingIcon class="h-5 w-10 mt-16"/>
            </div>
            <div class="flex flex-col gap-2 text-gray-100 text-sm" ref="chatListContainerRef" v-show="dataLoaded">
              <a class="flex py-3 px-3 items-center gap-3 relative rounded-md cursor-pointer break-all group"
                 v-for="chat in chats"
                 :key="chat.id"
                 :class="{'pr-14 bg-gray-800 hover:bg-gray-800': chat.id === selectedChatId,'hover:bg-[#2A2B32]':chat.id !== selectedChatId}"
                 @click="selectChat(chat.id, false)">
                <LoadingIcon v-if="chat.status==='requesting'" class="h-2 w-4"/>
                <Square3Stack3DIcon v-else-if="chat.type==='task'" class="h-4 w-4"/>
                <ChatBubbleBottomCenterTextIcon v-else class="h-4 w-4"/>
                <div class="flex flex-col gap-1 flex-1">
                  <div class="max-h-5 relative text-ellipsis overflow-hidden break-all ">{{ chat.title }}</div>
                  <div v-if="chat.messages?.length" class="text-xs text-gray-400">{{ chat.messages?.length }} Messages</div>
                </div>
                <div v-if="chat.id === selectedChatId" class="absolute flex right-1 z-10 text-gray-300 visible">
                  <button @click.stop="updateTitle()" class="p-1 hover:text-white">
                    <PencilIcon class="h-4 w-4"/>
                  </button>
                  <button @click.stop="deleteChat(chat.id)" class="p-1 hover:text-white">
                    <TrashIcon class="h-4 w-4"/>
                  </button>
                </div>
              </a>
            </div>
          </div>

          <div class="pt-0.5">
            <div class="relative">
              <button ref="settingPanelBtnRef" @click="showSidebarSetting=!showSidebarSetting"
                      class="flex w-full items-center gap-2.5 rounded py-3 px-3 text-sm text-gray-200 hover:text-white transition-colors duration-200 hover:bg-gray-800">
                <img v-if="settingStore.avatar" class="rounded-md h-6 w-6" :src="settingStore.avatar">
                <span class="grow text-left">ChatGPT</span>
                <ChevronDoubleUpIcon v-show="!showSidebarSetting" class="h-4 w-4 flex-shrink-0"/>
                <ChevronDoubleDownIcon v-show="showSidebarSetting" class="h-4 w-4 flex-shrink-0"/>
              </button>
              <Transition enter-active-class="transition ease-out duration-100 transform"
                          enter-from-class="opacity-0 translate-y-5"
                          enter-to-class="opacity-100"
                          leave-active-class="transition ease-in duration-100 transform"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0 translate-y-5">
                <div v-show="showSidebarSetting" ref="settingPanelRef" class="absolute left-0 bottom-full z-20 mb-2 w-full overflow-hidden rounded bg-[#050509] py-1.5 outline-none opacity-100 translate-y-0">
                  <nav class="flex flex-col">
                    <button @click.stop="deleteAllChats" class="flex py-3 px-3 items-center rounded gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-700">
                      <TrashIcon class="h-4 w-4"/>
                      Delete All Chats
                    </button>
                    <button @click.stop="exportAllChats" class="flex py-3 px-3 items-center rounded gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-700">
                      <ArrowDownOnSquareStackIcon class="h-4 w-4"/>
                      Export All Chats
                    </button>
                    <button @click.stop="exportChat(currentChat)" class="flex py-3 px-3 items-center rounded gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-700">
                      <ArrowDownOnSquareIcon class="h-4 w-4"/>
                      Export Current Chat
                    </button>
                    <button @click.stop="$refs?.fileInput?.click()" class="flex py-3 px-3 items-center rounded gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-700">
                      <CloudArrowUpIcon class="h-4 w-4"/>
                      Import Chats
                    </button>
                    <button @click.stop="$emit('manage-prompts')"
                            class="flex py-3 px-3 items-center rounded gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-700">
                      <Squares2X2Icon class="h-4 w-4"/>
                      Manage Prompts
                    </button>
                    <button @click.stop="showGlobalSettingModal = true"
                            class="flex py-3 px-3 items-center rounded gap-3 transition-colors duration-200 text-white cursor-pointer text-sm hover:bg-gray-700">
                      <Cog6ToothIcon class="h-4 w-4"/>
                      Setting
                    </button>
                  </nav>
                </div>

              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <GlobalSettings :show="showGlobalSettingModal" @close="showGlobalSettingModal = false"/>
  <input type="file" ref="fileInput" @change="importChats" class="hidden" accept=".json" multiple>
</template>

<script setup>
import {
  ChatBubbleBottomCenterTextIcon,
  Cog6ToothIcon,
  ArrowDownOnSquareIcon,
  ArrowDownOnSquareStackIcon,
  CloudArrowUpIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  Square3Stack3DIcon,
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
  Squares2X2Icon,
} from '@heroicons/vue/24/solid';
import LoadingIcon from "@/assets/icons/LoadingIcon.vue";
import GlobalSettings from "./modals/GlobalSettings.vue";

</script>

<script>
import ImportConversation from '@/scripts/ImportConversation';
import {renderMarkdown} from '@/scripts/MessageRender';

import JSZip from 'jszip';
import {mapState} from 'pinia'
import {mapWritableState} from 'pinia'

import {useChatsStore} from '@/stores/chat'
import {useSettingsStore} from "@/stores/settings";

export default {
  props: {
    showSidebar: Boolean,
    dataLoaded: Boolean,
  },
  emits: ['create-new-task', 'manage-prompts', 'close'],
  data() {
    const chatsStore = useChatsStore();
    const settingStore = useSettingsStore();
    return {
      chatsStore: chatsStore,
      settingStore: settingStore,
      showSidebarSetting: false,
      showGlobalSettingModal: false,
    };
  },
  mounted() {
    if (this.selectedChatId) {
      this.selectChat(this.selectedChatId);
    }
    document.addEventListener('click', this.closeSetting);
  },

  unmounted() {
    document.removeEventListener('click', this.closeSetting);
  },

  watch: {
    selectedChatId(newIndex) {
      localStorage.setItem("selectedChatId", newIndex);
    },
  },
  computed: {
    ...mapState(useChatsStore, ['chats', 'currentChat']),
    ...mapWritableState(useChatsStore, ['selectedChatId']),
  },
  methods: {
    selectChat(chatId, scrollIntoView = true) {
      this.selectedChatId = chatId; // Set the selectedChatId to the desired chatId
      this.$emit('close');
      if (scrollIntoView) {
        this.$nextTick(() => {
          this.$refs.chatListContainerRef.children[this.chats.findIndex(chat => chat.id === this.selectedChatId)]?.scrollIntoView();
        });
      }
    },

    closeSetting(e) {
      if (this.showSidebarSetting && !this.$refs.settingPanelBtnRef.contains(e.target) && !this.$refs.settingPanelRef.contains(e.target)) {
        this.showSidebarSetting = false;
      }
    },

    createChat(select = true, params) {
      return this.chatsStore.create(select, params);
    },

    deleteChat(chatId) {
      if (confirm("Are you sure you want to delete this chat?")) {
        const chatIndex = this.chats.findIndex(chat => chat.id === chatId);
        this.chats.splice(chatIndex, 1);
        this.chatsStore.deleteChat(chatId);

        if (chatId === this.selectedChatId && this.chats.length !== 0) {
          this.selectedChatId = this.chats[0].id;
        }
      }
    }
    ,
    deleteAllChats() {
      if (confirm("Are you sure you want to delete all chats?")) {
        this.chats.forEach((chat) => {
          this.chatsStore.deleteChat(chat.id);
        });
        this.chats.splice(0, this.chats.length);
        this.selectedChatId = null;
      }
    },

    async importChats(event) {
      for (const file of event.target.files) {
        if (!file) {
          return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const chatJson = JSON.parse(e.target.result);
            let chat;
            if (chatJson.title && chatJson.id && chatJson.messages !== undefined) {
              chat = this.createChat(false);
              delete chatJson.id;
              delete chatJson.timestamp;
              chatJson.messages = chatJson.messages.map((item) => {
                if (item.role === 'assistant') {
                  item.rendered_content = renderMarkdown(item.content);
                }
                return item;
              });

              for (const prop in chatJson) {
                if (Object.prototype.hasOwnProperty.call(chat, prop)) {
                  chat[prop] = chatJson[prop]
                }
              }
            } else {
              // ChatGPT
              const conversations = ImportConversation.processChatGPTConversation(chatJson)

              const messages = conversations.conversationNodes.map((item) => {
                const msg = {
                  role: item.message.author.role,
                  content: item.message.content.parts.join(''),
                }
                if (msg.role === 'assistant') {
                  msg.rendered_content = renderMarkdown(msg.content);
                }
                return msg;
              });
              chat = this.createChat(false);
              chat.messages = messages;
              chat.model = conversations.model.toLowerCase();
              chat.title = conversations.title;
            }

            await this.chatsStore.saveChat(chat);
            this.$toast.success(`Import ${chat.title} successfully!`);
          } catch (error) {
            this.$toast.error("Import Failed! Only support this site and chat.openai.com.");
          }
        };
        reader.readAsText(file);
      }
    },

    async exportChat(chat) {
      if (!chat)
        return
      const chatData = JSON.parse(JSON.stringify(chat));
      // chatData.messages = chatData.messages.map(item => ({
      //   role: item.role,
      //   content: item.content,
      // }));
      const chatJson = JSON.stringify(chatData);
      const filename = `${chatData.title}-${new Date().getTime()}.json`;

      const blob = new Blob([chatJson], {type: "application/json"});

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      link.click();
      URL.revokeObjectURL(link.href);
    },

    async exportAllChats() {
      if (this.chats.length === 0) {
        return
      }
      const zip = new JSZip();
      const chats = JSON.parse(JSON.stringify(this.chats));
      chats.forEach((chat) => {
        // chat.messages = chat.messages.map(item => ({
        //   role: item.role,
        //   content: item.content,
        // }));
        const chatJson = JSON.stringify(chat);
        const filename = `${chat.title}-${new Date().getTime()}.json`;

        zip.file(filename, chatJson);
      });

      const blob = await zip.generateAsync({type: "blob"});

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `chats-${new Date().getTime()}.zip`;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    updateTitle() {
      const newTitle = window.prompt("请输入新的标题", this.currentChat.title)?.trim();
      if (newTitle && newTitle !== this.currentChat.title) {
        this.currentChat.title = newTitle; //will trigger updateChatSettings
      }
    },
  },
}
</script>
