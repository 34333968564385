<template>
  <svg :class="$attrs.class" viewBox="0 0 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(50 50)">
      <circle cx="0" cy="0" r="15" fill="#e15b64">
        <animateTransform attributeName="transform" type="scale" begin="-0.3333333333333333s"
                          calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1"
                          dur="1s" repeatCount="indefinite"></animateTransform>
      </circle>
    </g>
    <g transform="translate(100 50)">
      <circle cx="0" cy="0" r="15" fill="#f8b26a">
        <animateTransform attributeName="transform" type="scale" begin="-0.16666666666666666s"
                          calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1"
                          dur="1s" repeatCount="indefinite"></animateTransform>
      </circle>
    </g>
    <g transform="translate(150 50)">
      <circle cx="0" cy="0" r="15" fill="#99c959">
        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                          repeatCount="indefinite"></animateTransform>
      </circle>
    </g>
  </svg>
</template>