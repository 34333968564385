<template>
  <textarea :value="modelValue" @input="$emit('update:modelValue', $event.target.value);"></textarea>
</template>

<script>
export default {
  name: "AutosizeTextarea",
  props: {
    maxHeight: Number,
    modelValue: String,
  },
  mounted() {
    this.resizeInput();
  },
  updated() {
    this.resizeInput();
  },
  methods: {
    resizeInput() {
      this.$nextTick(() => {
        const inputField = this.$el;
        if (inputField) {
          let newHeight;
          inputField.style.height = "auto"; // Temporarily set the height to "auto" to calculate the new height
          if (this.maxHeight) {
            newHeight = Math.min(inputField.scrollHeight, this.maxHeight); // Calculate the new height (up to 5 lines)
          } else {
            newHeight = inputField.scrollHeight;
          }
          inputField.style.height = `${newHeight}px`; // Set the new height
        }
      });
    },
  }
}
</script>