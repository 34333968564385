<template>
  <div tabindex="-1" aria-hidden="true"
       v-if="show" :class="position"
       class="z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div @click="$emit('close')" v-if="show"
         class="fixed inset-0 bg-black bg-opacity-25"
    ></div>
    <div :class="width" class="relative w-full max-h-full mx-auto mt-10 z-51">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow-lg dark:bg-gray-700">
        <!-- Modal header -->
        <div :class="{'border-b border-dashed': !$slots.header}"
             class="flex items-center justify-between px-4 py-2 rounded-t dark:border-gray-600">
          <slot name="header">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white overflow-hidden text-ellipsis">
              {{ title }}
            </h3>
          </slot>
          <button type="button" @click="$emit('close')"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
            <XMarkIcon class="w-5 h-5"/>
          </button>
        </div>
        <!-- Modal body -->
        <div class="px-6 py-3">
          <slot/>
        </div>
        <slot name="footer"/>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  XMarkIcon
} from '@heroicons/vue/24/solid';
</script>
<script>
export default {
  emits: ['close'],
  props: {
    title: String,
    show: Boolean,
    position: {
      type: String,
      required: false,
      default: 'fixed top-0 left-0 right-0',
    },
    width: {
      type: String,
      required: false,
      default: 'max-w-2xl',
    },
  },
  mounted() {
    document.addEventListener("keydown", this.keyEvents);
  },
  unmounted() {
    document.removeEventListener("keydown", this.keyEvents);
  },
  methods: {
    keyEvents(event) {
      if (this.show && event.key === "Escape") {
        this.$emit('close');
      }
    },
  }
}
</script>
