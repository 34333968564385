export async function requestCompletion(prompt, options, controller) {
    const apiKey = options.openaiKey;
    let apiHost = options.apiHost.endsWith('/') ? options.apiHost : options.apiHost + '/'
    const apiUrl = apiHost + 'v1/chat/completions';
    const stream = options.stream === undefined ? true : options.stream;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
            messages: prompt,
            model: options.model,
            temperature: options.temperature,
            stream: stream,
        }),
        signal: controller?.signal,
    };

    console.log(prompt);

    try {
        const reqTimeoutId = setTimeout(() => controller?.abort(), 30_000);
        const response = await fetch(apiUrl, requestOptions);
        clearTimeout(reqTimeoutId);

        let responseText = "";

        if (response.ok) {
            if (stream) {
                const reader = response.body?.getReader();
                clearTimeout(reqTimeoutId);

                const decoder = new TextDecoder();
                const readChunk = async () => {
                    return reader.read().then(async ({value, done}) => {
                        if (!done) {
                            value = decoder.decode(value);
                            const lines = value.toString().split('\n').filter(line => line.trim() !== '');
                            for (const line of lines) {
                                const message = line.replace(/^data: /, '');// data: [real data]
                                if (message === "[DONE]") continue;
                                let parsed = JSON.parse(message);
                                let content = parsed.choices[0].delta.content;
                                if (content) {
                                    responseText += content;
                                    options?.onUpdate(responseText);
                                }
                            }
                            return readChunk();
                        }
                    });
                };
                await readChunk();
                if (options?.onFinish) {
                    options.onFinish(responseText);
                }
            } else {
                let res = await response.json();
                if (options?.onFinish) {
                    options.onFinish(res?.choices?.at(0)?.message?.content ?? '');
                }
            }

        } else {
            const errorMsg = (await response.json())?.error?.message;
            let statusText;
            if (response.status === 401) {
                statusText = errorMsg ?? "API key error or expired, please check your API key! ";
            } else if (response.status === 400) {
                statusText = errorMsg ?? "Request content too large, please decrease context length in settings! ";
            } else if (response.status === 404) {
                statusText = errorMsg ?? "Not authorized to use this model, please select another GPT model in settings! ";
            } else if (response.status === 429) {
                statusText = errorMsg ?? response.statusText ? "API call frequency limit exceeded, please try again later! " : "API usage exceed limit, please check your bill! ";
            } else {
                statusText = errorMsg ?? "Gateway error or timeout, please try again later! ";
            }
            if (options?.onError) {
                options.onError(new Error(statusText), response.status);
            }
        }
    } catch (err) {
        if (options?.onError) {
            options.onError(err);
        }
    }
}

