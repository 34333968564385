<template>
  <BaseModal :title="name ?? 'New Prompt'" :show="show" position="absolute">
    <div class="mb-4">
      <label for="promptName" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Name</label>
      <input type="text" id="promptName" v-model.trim="promptName"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    </div>
    <div class="mb-4">
      <label for="promptAct" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Act</label>
      <input type="text" id="promptAct" v-model.trim="promptAct"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    </div>
    <div class="mb-4">
      <label for="promptContent" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Prompt</label>
      <AutosizeTextarea
          id="promptContent" rows="3" v-model.trim="promptContent" ref="inputField"
          class="block p-2.5 w-full resize-y text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></AutosizeTextarea>
    </div>
    <template #footer>
      <div class="flex items-center px-6 pb-6 rounded-b">
        <button type="button" @click="createPrompt()" class="inline-flex justify-center rounded-md text-sm font-semibold py-2 px-3 bg-slate-900 text-white hover:bg-slate-800 hover:bg-opacity-80 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:focus:ring-slate-800">Save</button>
      </div>
    </template>
  </BaseModal>

</template>
<script setup>
</script>
<script>
import BaseModal from "./BaseModal.vue";
import AutosizeTextarea from "@/components/ui/AutosizeTextarea.vue"
import {useChatsStore} from "@/stores/chat";

export default {
  components: {BaseModal, AutosizeTextarea},
  props: ['name', 'act', 'prompt', 'show', 'promptId'],
  data() {
    const chatsStore = useChatsStore();
    return {
      promptName: this.name,
      promptAct: this.act,
      promptContent: this.prompt,
      chatsStore: chatsStore,
    };
  },

  methods: {
    createPrompt() {
      if (!this.promptName || !this.promptContent) {
        this.$toast.error('Empty content!');
        return
      }
      if (this.promptId === undefined) {
        this.chatsStore.createPrompt(this.promptName, this.promptAct, this.promptContent)
      } else {
        this.chatsStore.prompts[this.promptId].name = this.promptName;
        this.chatsStore.prompts[this.promptId].act = this.promptAct;
        this.chatsStore.prompts[this.promptId].prompt = this.promptContent;
        this.chatsStore.savePrompt();
      }
      this.$emit('close');
    },
  },

  watch: {
    name() {
      this.promptName = this.name;
    },
    act() {
      this.promptAct = this.act;
    },
    prompt() {
      this.promptContent = this.prompt;
    },
  },
};
</script>