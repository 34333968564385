<template>
    <BaseModal title="Upload File" :show="show" @close="$emit('close')" position="absolute" width="lg:max-w-5xl">
        <div class="mb-4">
            <div @click="$refs?.fileInput?.click()"
                 class="w-full cursor-pointer p-5 bg-white border-2 border-dashed hover:border-solid border-slate-300 rounded-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-400 dark:hover:bg-gray-900">
                <div class="flex min-h-[5rem] w-full flex-col items-center font-bold tracking-tight text-gray-900 dark:text-white">
                    <CloudArrowUpIcon class="w-8 h-8 mt-1"/>
                    <span class="text-xl mt-1">Upload files</span>
                </div>
            </div>
        </div>
        <div class="flex justify-center items-center mb-4" v-if="files.length > 0">
            <button type="button" @click="createTaskFromFile()" class="inline-flex justify-center rounded-md text-sm font-semibold py-2 px-3 bg-slate-900 text-white hover:bg-slate-800 hover:bg-opacity-80 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:focus:ring-slate-800">
                Submit
            </button>
        </div>
        <div class="mb-4" v-if="files.length > 0">
            <div class="grid md:grid-cols-2 gap-3">
                <div @click="choice='line'"
                     :class="{'ring-2 ring-amber-600' : choice === 'line', 'ring-1 ring-slate-700/10 dark:ring-slate-100/10' : choice !== 'line'}"
                     class="cursor-pointer rounded-md dark:bg-gray-800 p-4 text-[0.8125rem] leading-5 shadow-xl shadow-black/5 hover:bg-slate-50 dark:hover:bg-gray-900">
                    <div class="flex justify-between">
                        <div class="font-medium dark:text-white">\n</div>
                        <CheckCircleIcon v-show="choice==='line'" class="h-5 w-5 flex-none text-amber-600"/>
                    </div>
                    <div class="mt-1 text-slate-700 dark:text-slate-200">Split content using "\n"</div>
                    <div v-show="choice==='line'" class="mt-2 font-medium text-slate-900 dark:text-slate-50">{{ previewFile.length }} splits</div>
                </div>
                <div @click="choice='regex'"
                     :class="{'ring-2 ring-amber-600' : choice === 'regex', 'ring-1 ring-slate-700/10 dark:ring-slate-100/10' : choice !== 'regex'}"
                     class="cursor-pointer rounded-md dark:bg-gray-800 p-4 text-[0.8125rem] leading-5 shadow-xl shadow-black/5 hover:bg-slate-50 dark:hover:bg-gray-900">
                    <div class="flex justify-between">
                        <div class="font-medium dark:text-white">Regex</div>
                        <CheckCircleIcon v-show="choice==='regex'" class="h-5 w-5 flex-none text-amber-600"/>
                    </div>
                    <div class="mt-1 text-slate-700 dark:text-slate-200">Split content using regex</div>
                    <div v-show="choice==='regex'" class="mt-2 font-medium text-slate-900 dark:text-slate-50">{{ previewFile.length }} splits</div>
                </div>
            </div>
        </div>

        <div class="mb-4" v-if="files.length > 0 && choice === 'regex'">
            <label for="replaceRegex" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Remove</label>
            <AutosizeTextarea type="text" id="replaceRegex" v-model.trim="replaceRegex" rows="2"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></AutosizeTextarea>

            <label for="splitRegex" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Split with</label>
            <input type="text" id="splitRegex" v-model.trim="splitRegex"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        </div>

        <div class="mb-4" v-if="files.length > 0">
            <label for="maxSplitLength" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Max Split Length</label>
            <input type="number" id="maxSplitLength" v-model.number="maxSplitLength" min="1"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        </div>

        <div class="mb-4 dark:text-white" v-if="files.length > 0">
            <div class="flex flex-row justify-start items-center mb-2">
                <label for="previewFileIdx" class="font-medium dark:text-white mr-1">Result preview</label>
                <select id="previewFileIdx" v-model="previewFileIdx" class="dark:bg-gray-700">
                    <option v-for="(file, index) in files" :key="index" :value="index">{{ file.name }}:</option>
                </select>
                <ChevronDownIcon class="w-4 h-4 hidden"/>
            </div>
            <div v-for="(split, index) in previewFile" :key="index" class="grid grid-cols-1 gap-3 mb-2">
                <div class="p-1.5 border rounded bg-gray-200 dark:bg-gray-800 dark:border-gray-600 whitespace-pre-line">
                    <div class="bg-white dark:bg-gray-700 p-2 rounded">{{ split }}</div>
                </div>
                <!--        <div class="p-1.5 border rounded bg-gray-200 dark:bg-gray-800 dark:border-gray-600 whitespace-pre-line h-full">-->
                <!--          <div class="bg-white dark:bg-gray-700 p-2 rounded h-full">RESULT</div>-->
                <!--        </div>-->
            </div>
        </div>
        <template #footer>
            <div class="flex justify-center items-center px-6 pb-6">
                <button type="button" @click="createTaskFromFile()" class="inline-flex justify-center rounded-md text-sm font-semibold py-2 px-3 bg-slate-900 text-white hover:bg-slate-800 hover:bg-opacity-80 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:focus:ring-slate-800">
                    Submit
                </button>
            </div>
        </template>
    </BaseModal>
    <input type="file" ref="fileInput" @change="importFiles" class="hidden" multiple>
</template>

<script setup>
import {CloudArrowUpIcon, CheckCircleIcon, ChevronDownIcon} from '@heroicons/vue/24/solid';
</script>

<script>
import BaseModal from "./BaseModal.vue";
import AutosizeTextarea from "@/components/ui/AutosizeTextarea.vue"
import {parseRegex} from "@/scripts/Utils";
// /(?<!\\)%.*?$/gm
// /\\(?:begin|end)\{(?:equation|align|algorithm|figure|table)\*?}(?:\n|.*?)*?\\(?:end|begin)\{(?:equation|align|algorithm|figure|table)\*?}/gs
// /\\begin\{tabular\}.*?\\end\{tabular\}/gs
// /\\(?:newcommand|keywords|makedeclaration|maketitle|intobmk|setcounter|pagenumbering|pagestyle|cleardoublepage)(?:\[[^]]*\])?(?:\{[^}]*\})?/g
// /\\(?:chapter|section|subsection|subsubsection|label|newcommand|keywords|makedeclaration|maketitle|intobmk|setcounter|pagenumbering|pagestyle|cleardoublepage)\{.*?\}/g
// /\\begin\{enumerate\}/g
// /\\end\{enumerate\}/g
// /\\begin\{itemize\}/g
// /\\end\{itemize\}/g
// /\\item/g
export default {
    components: {BaseModal, AutosizeTextarea},
    emits: ['close', 'create-task-from-file'],
    props: {
        show: Boolean,
        metaData: Object,
    },
    computed: {
        previewFile() {
            if (this.previewFileIdx < 0 || this.previewFileIdx >= this.files.length) return null;
            return this.processFile(this.files[this.previewFileIdx].content, this.choice).slice(0, 50);
        }
    },
    data() {
        return {
            choice: 'line',
            replaceRegex: '',
            splitRegex: "/\\n\\s*\\n/",
            maxSplitLength: 512,
            previewFileIdx: 0,
            files: [],
        };
    },

    mounted() {
        this.choice = this.metaData?.choice ?? 'line';
        this.replaceRegex = this.metaData?.replaceRegex ?? '';
        this.splitRegex = this.metaData?.splitRegex ?? "/\\n\\s*\\n/";
        this.maxSplitLength = this.metaData?.maxSplitLength ?? 512;
    },
    unmounted() {
        this.files = [];
    },
    methods: {
        processFile(content, choice) {
            let result;
            if (choice === 'line') {
                result = content.split('\n').map(line => line.trim()).filter(line => line.length > 0);
            } else if (choice === 'regex') {
                result = content;
                try {
                    for (const item of this.replaceRegex.split('\n')) {
                        let userInput = item.trim();
                        if (userInput.length > 0) {
                            let regex = parseRegex(userInput);
                            if (regex)
                                result = result.replace(regex, '\n');
                        }
                    }

                    if (this.splitRegex) {
                        const splitRegex = parseRegex(this.splitRegex);
                        if (splitRegex) {
                            result = result.split(splitRegex).map(line => line.trim()).filter(line => line.length > 0);
                        }
                    }
                } catch (e) {
                    console.log(e);
                    return [content];
                }
            } else {
                result = [content];
            }
            return this.mergeStrings(result, this.maxSplitLength);
        },
        createTaskFromFile() {
            if (this.files.length === 0) {
                this.$toast.error('Please select at least one file.');
                return
            }
            const fileData = {};
            for (const file of this.files) {
                fileData[file.name] = this.processFile(file.content, this.choice);
            }
            const meta = {
                choice: this.choice,
                replaceRegex: this.replaceRegex,
                splitRegex: this.splitRegex,
                maxSplitLength: this.maxSplitLength,
            };
            this.$emit('create-task-from-file', fileData, meta);
            this.$emit('close')
        },
        mergeStrings(inputList, maxLen) {
            const outputList = [];
            let tempString = "";
            for (const item of inputList) {
                if (tempString.length + item.length + 2 <= maxLen) {
                    tempString = tempString ? (tempString + "\n\n") + item : item;
                } else {
                    if (tempString) {
                        outputList.push(tempString);
                        tempString = item;
                    } else {
                        outputList.push(item);
                    }
                }
            }
            if (tempString) {
                outputList.push(tempString);
            }
            return outputList;
        },

        async importFiles(event) {
            for (const file of event.target.files) {
                if (!file) {
                    return;
                }

                const reader = new FileReader();
                reader.onload = async (e) => {
                    try {
                        this.files.push({
                            name: file.name,
                            content: e.target.result,
                        });
                        console.log(`Import ${file.name} successfully!`);
                    } catch (error) {
                        this.$toast.error("Import file failed!");
                    }
                };
                reader.readAsText(file);
            }
        },
    },
}
</script>