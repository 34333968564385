import {createApp} from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

import ToastPlugin from "@/plugins/ToastPlugin";
import './index.css';
import './global.css';

const pinia = createPinia()
const app = createApp(App);

app.use(pinia);
app.use(ToastPlugin);
app.mount('#app')
