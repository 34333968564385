<template>
  <BaseModal :title="chatSettings.title" :show="show" position="absolute">
    <div class="mb-4">
      <label for="model" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Model</label>
      <select id="model" v-model="chatSettings.model"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
        <option value="gpt-3.5-turbo-0301">gpt-3.5-turbo-0301</option>
        <option value="gpt-4">gpt-4</option>
        <option value="gpt-4-0314">gpt-4-0314</option>
        <option value="gpt-4-32k">gpt-4-32k</option>
        <option value="gpt-4-32k-0314">gpt-4-32k-0314</option>
      </select>
    </div>
    <div class="mb-4">
      <label for="chatApiHost" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">API Host (for this instance)</label>
      <input type="text" id="chatApiHost" v-model.trim="chatSettings.apiHost"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             placeholder="https://api.openai.com/">
    </div>
    <div class="mb-4">
      <label for="chatOpenaiKey" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">OpenAI Key (for this instance)</label>
      <input :type="openaiKeyFocused ? 'text' : 'password'" id="chatOpenaiKey" v-model.trim="chatSettings.openaiKey"
             @focus="openaiKeyFocused=true" @blur="openaiKeyFocused=false"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             placeholder="sk-***">
    </div>
    <div class="mb-4">
      <label for="chatSystemRole" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">System Role</label>
      <AutosizeTextarea id="chatSystemRole" rows="2" v-model="chatSettings.systemRole"
                class="block p-2.5 w-full resize-y text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="System role"></AutosizeTextarea>

    </div>
    <div class="mb-4" v-if="chatSettings.type==='task'">
      <label for="taskPrompt" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Task Prompt</label>
      <AutosizeTextarea id="taskPrompt" rows="3" v-model="chatSettings.taskPrompt"
                class="block p-2.5 w-full resize-y text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Task prompt"></AutosizeTextarea>

    </div>
    <div class="mb-4" v-if="chatSettings.type!=='task'">
      <label for="chatContextLength" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Context Length</label>
      <input type="number" id="chatContextLength" v-model.number="chatSettings.contextLength" min="0"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    </div>
    <div class="mb-4">
      <label for="chatTemperature" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Temperature ({{ chatSettings.temperature }})</label>
      <input type="range" id="chatTemperature" v-model.number="chatSettings.temperature" min="0" max="2" step="0.1"
             class="w-full h-2 bg-gray-200 rounded-md appearance-none cursor-pointer dark:bg-gray-800">
    </div>
  </BaseModal>

</template>
<script setup>
</script>
<script>
import BaseModal from "./BaseModal.vue";
import AutosizeTextarea from "@/components/ui/AutosizeTextarea.vue";
import {getNonArrayProps} from "@/scripts/Utils";
import {useChatsStore} from "@/stores/chat";

export default {
  components: {BaseModal, AutosizeTextarea},
  props: {
    currentChat: {
      type: Object,
      required: true,
    },
    show: Boolean,
  },
  data() {
    const chatsStore = useChatsStore();
    return {
      chatsStore: chatsStore,
      chatSettings: getNonArrayProps(this.currentChat),
      openaiKeyFocused: false,
    };
  },

  mounted() {
    for (const key in this.currentChat) {
      if (!Array.isArray(this.currentChat[key])) {
        this.$watch('currentChat.' + key, () => {
          this.chatSettings[key] = this.currentChat[key];
        })
      }
    }
  },

  watch: {
    chatSettings: {
      handler(newSettings) {
        if (newSettings.id === this.currentChat.id) {
          this.chatsStore.updateChatSettings(newSettings);
        }
      },
      deep: true,
    },
  },
};
</script>