export async function copyToClipboard(text, toast) {
    try {
        await navigator.clipboard.writeText(text);
        toast?.success('Copied!');
    } catch (error) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
            toast?.success('Copied!');
        } catch (error) {
            toast?.err('Failed!');
        }
        document.body.removeChild(textArea);
    }
}


export class ScrollToBottomController {
    previousScrollHeight = 0;

    toBottom(target, force) {
        if (target) {
            const currentScrollHeight = target.scrollHeight;
            const isAtBottom = target.scrollHeight - target.scrollTop <= target.clientHeight + 64;
            if (force || (this.previousScrollHeight !== currentScrollHeight && isAtBottom)) {
                target.scrollTop = currentScrollHeight;
            }
            this.previousScrollHeight = currentScrollHeight;
        }
    }
}

export function getNonArrayProps(obj) {
    const res = {}
    for (const key in obj) {
        if (!Array.isArray(obj[key])) {
            res[key] = obj[key];
        }
    }
    return res
}


export function parseRegex(input) {
    const firstSlashIndex = input.indexOf('/');
    if (firstSlashIndex === -1) {
        return new RegExp(input);
    }
    const lastSlashIndex = input.lastIndexOf('/');
    const pattern = input.slice(1, lastSlashIndex);
    const flags = input.slice(lastSlashIndex + 1);
    return new RegExp(pattern, flags);
}

export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});

}