<template>
  <AppWindow/>
</template>

<script>
import AppWindow from "@/components/AppWindow.vue";

export default {
  name: 'App',
  components: {
    AppWindow,
  }
}
</script>

